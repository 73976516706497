
import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import UpgradesMenu from "./UpgradesMenu";
import UpgradesContent from "./UpgradesContent";
import {useSelector} from "react-redux";

const Upgrades = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Upgrades</title>
            </Helmet>
            <Navbar/>

            <section className="upgrades-sec">
                <div className="container">
                    <div className="row">
                        <UpgradesMenu/>
                        <UpgradesContent/>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Upgrades;
