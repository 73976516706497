import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeEmail} from "../../actions/authAction";

const PrivacyEmail = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        email: '',
    })

    const handleInputChange = (e) => {
        setUserDetails({...userDetails, email: e.target.value})
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        setLoader(true);

        dispatch(changeEmail(userDetails, setLoader))
        setUserDetails({...userDetails, email: ''})
    }

    return(
        
        <>
            <section className="privacy-setting">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mx-auto">
                            <div className="personal-details-box">
                                <h5 className="mb-5 text-left">Change Email</h5>
                                <form className="personal-details-box-form" method="post" onSubmit={(e)=>handleFormSubmit(e)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-3">
                                                <small className="form-text text-muted">Current Email Address</small>
                                                <input type="text" className="form-control" id="InputName" readOnly value={auth.user.email}
                                                       placeholder="Enter Name" name="email" onChange={(e)=>handleInputChange(e)}/>
                                             </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-3">
                                                <small className="form-text text-muted">New Email Address</small>
                                                <input type="email" className="form-control" id="InputEmail" value={userDetails.email}
                                                       aria-describedby="emailHelp" placeholder="Email Address" name="email" onChange={(e)=>handleInputChange(e)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn gradiant-button-pb btn-submit mt-4">
                                        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update

                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyEmail;
