import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import queryString from "query-string";
import {resetPassword} from "../../actions/authAction";
import {Helmet} from "react-helmet";
import Header from "./Header";
import Alert from "../Alert";
import Footer from "../Footer";

const ResetPassword = ({location}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const handleInputChange = (e) => {
        setPassword({...password, [e.target.name]: e.target.value});
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(resetPassword(password, setLoader, history));
    }

    return (
        <>

            <Helmet>
                <title> PlayerReel | Reset Password </title>
            </Helmet>
            <div id="login-page" className="login-page">
                <div className="login-sec">
                    <Header/>
                    <Alert/>
                    <section id="login-page" className="login-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6 d-flex align-items-center">
                                    <div className="login-content text-center">
                                        <h1>Welcome <span>Back !</span></h1>
                                        <p>Turn any video or video URL into a beautifully customized video player in seconds. Watch PlayerReel instantly create a unique, traffic-getting videos player that you and your clients will love.</p>
                                        {/*<h5 className="mt-3">…It’s Super Easy-To-Use</h5>*/}
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <form className="login-form text-white" method="post"
                                          onSubmit={(e) => handleFormSubmit(e)}>
                                        <h4><span>Reset</span> Password</h4>
                                        <div className="form-group mt-4">
                                            <label>New Password</label>
                                            <input type="password" name="password" className="form-control"
                                                   placeholder="************"
                                                   required onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" name="confirm_password" className="form-control"
                                                   placeholder="************" required
                                                   onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>

                                        <button type="submit" className="btn gradiant-button-pb btn-submit"
                                                disabled={loader}>
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Reset Password
                                        </button>
                                        <p className="forgot-account mt-4">Have a Login ?
                                            <Link to="/login"> Click here</Link>
                                        </p>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;
