import mac_1 from "../../images/mac1.png";
import mac_2 from "../../images/mac2.png";
import mac_3 from "../../images/mac3.png";
import mac_4 from "../../images/mac4.png";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const DashboardAbout = ()=>{
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])
    return(
        <>
        <div className="services-wrap">
            <section className="services">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="right-img">
                                <img src={mac_1} className="laptop-img" alt="mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 position-relative">
                            <div className="left-decription services-desciption pt-sm-3">
                                 <h2>Turn URLs into a 100% brandable video player</h2>
                                <p className="mt-4">Instantly convert any URL from YouTube, Vimeo, or files from your online storage into a sleek video player that you can fully-customize according to your business and your clients’ needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="left-img">
                                <img src={mac_2} className="laptop-img-2" alt="mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 position-relative">
                            <div className="right-decription services-desciption pt-sm-3">
{/*
                                <h5 className="subtitle">Polish your videos effortlessly</h5>
*/}
                                <h2>Create video players that do all of the marketing for you </h2>
                                <p className="mt-4">Automatically follow up your viewers with clickable call-to-action buttons, opt-in forms, social media sharing prompts, and link buttons inside your videos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="right-img">
                                <img src={mac_3} className="laptop-img" alt="mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 position-relative">
                            <div className="left-decription services-desciption pt-sm-3">
{/*
                                <h5 className="subtitle">Boost your viewership and watch time</h5>
*/}
                                <h2>1-click integration into any autoresponder</h2>
                                <p className="mt-4">Effortlessly integrate any autoresponder just by pasting your optin code. No more need for API implementation or any of that technical stuff.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="left-img">
                                <img src={mac_4} className="laptop-img-2" alt="mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 position-relative">
                            <div className="right-decription services-desciption pt-sm-3">
                                 <h2>Embed your videos on any website</h2>
                                <p className="mt-4">Showcase your video players wherever you need it.
                                    { rebrand.data === false ? <span>PlayerReel</span> : <span>{rebrand.data.name}</span>
                                    } instantly creates a ready-to-embed video player for your website, landing pages, funnels, and more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
            </>
    )
}
export default DashboardAbout;
