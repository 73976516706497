import React from "react";
import Navbar from "../Navbar";
import ProfileImage from "./ProfileImage";
import ProfileName from "./ProfileName";
import ProfilePassword from "./ProfilePassword";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";

const Profile = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Profile </title>
            </Helmet>
            <Navbar/>
            <section className="profile-sec">
                <div className="container">
                    <div className="row">
                        <ProfileImage/>
                        <ProfileName/>
                        <ProfilePassword/>
                    </div>
                </div>
            </section>
            <Footer/>

        </>
    )
}

export default Profile;
