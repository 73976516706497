import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import axios from "axios";
import { baseURL } from "../../global/global";
import ManageAccount from "./ManageAccount";
import { useSelector } from "react-redux";

function ResellerAccount() {
  const auth = useSelector((state) => state.auth);

  const [resellerData, setResellerData] = useState([]);

  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    name: "",
    password: "",
  });

  const fetchAccountData = () => {
    axios({
      method: "POST",
      url: `${baseURL}fetch-reseller`,
      data: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          setResellerData(res.data.accounts);
        }
      })
      .catch((err) => {});
  };

  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const addReseller = () => {
    axios({
      method: "POST",
      url: `${baseURL}add-reseller`,
      data: userDetails,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          fetchAccountData();
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    addReseller();
  };

  useEffect(() => {
    fetchAccountData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container">
        <form
          className="row text-left"
          method="post"
          onSubmit={(e) => handleFormSubmit(e)}
        >
          <div className="col-md-4">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter name"
                name="name"
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                name="email"
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-primary">
              Create
            </button>
          </div>
        </form>

        <div className="mt-5"></div>
        <ManageAccount
          type="Reseller"
          users={resellerData}
          auth={auth}
          fetchAccountData={fetchAccountData}
        />
      </div>
    </>
  );
}

export default ResellerAccount;
