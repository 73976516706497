import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateName} from "../../actions/authAction";

const ProfileName = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);

    const [userName, setUserName] = useState({
        name: auth.user.name,
        email: auth.user.email
    })

    const handleInputChange = (e) => {
        setUserName({...userName, [e.target.name] : e.target.value})
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(updateName(userName, setLoader));
    }

    return(
        <>

            <div className="col-12 col-md-12 mx-auto">
                <div className="personal-details-box">
                    <form className="personal-details-box-form" method="post" onSubmit={handleFormSubmit}>
                        <h6 className="mb-5 text-center">Personal Information</h6>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-3">
                                    <small className="form-text text-muted">Profile Name</small>
                                    <input type="text" className="form-control" id="InputName" placeholder="Enter Name" name="name" value={userName.name} required onChange={(e)=>handleInputChange(e)} />
                                 </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-3">
                                    <small className="form-text text-muted">Your Email Address </small>
                                    <input type="email" className="form-control" id="InputEmail"
                                           aria-describedby="emailHelp" placeholder="Email Address" name="email" value={userName.email} readOnly/>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn gradiant-button-pb btn-submit mt-4">
                            { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update
                        </button>
                    </form>
                </div>

            </div>
        </>
    )
}

export default ProfileName;
