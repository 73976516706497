import React, {useState} from "react"
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../actions/authAction";
import Footer from "../Footer";
import Header from "./Header";
import {Helmet} from "react-helmet";
import Alert from "../Alert";

const Login = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const handleInputChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});

    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(loginUser(user, setLoader));
    }

    if (auth.isAuthenticated) {
        history.push('/dashboard');
    }

    return (
        <>
            <Helmet>
                <title> PlayerReel | Login </title>
            </Helmet>
            <div id="login-page" className="login-page">
                <div className="login-sec">
                    <Header/>
                    <Alert/>

                    <section id="login-page" className="login-box">
                        <div className="container">
                            <div className="row" method="post" onSubmit={(e) => handleFormSubmit(e)}>
                                <div className="col-12 col-md-6 d-flex align-items-center">
                                    <div className="login-content text-center">
                                        <h1>Welcome <span>Back !</span></h1>
                                        <p>Turn any video or video URL into a beautifully customized video player in seconds. Watch PlayerReel instantly create a unique, traffic-getting videos player that you and your clients will love.</p>
                                        {/*<h5 className="mt-3">…It’s Super Easy-To-Use</h5>*/}
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <form className="login-form">
                                        <h4 className="mb-4"><span className="highlight red">Login</span> to Your
                                            Account
                                            Now
                                        </h4>
                                        <div className="mb-4">
                                            <label htmlFor="InputEmail" className="form-label">Email Address</label>
                                            <input type="email" className="form-control auth" required id="InputEmail"
                                                   aria-describedby="emailHelp" placeholder="Email Address" name="email"
                                                   onChange={(e) => handleInputChange(e)}/>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="InputPassword1" className="form-label">Password</label>
                                            <input type="password" required className="form-control auth" id="InputPassword1"
                                                   placeholder="***********" name="password"
                                                   onChange={(e) => handleInputChange(e)}/>
                                        </div>

                                        <button type="submit" className="btn gradiant-button-pb btn-submit">
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Login to
                                            PlayerReel
                                        </button>
                                        <p className="forgot-account mt-4">Forgot your account details ?
                                            <Link to={`/forget-password`}> Forget Password</Link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        </>
    )
}

export default Login;
