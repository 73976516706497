import React, {useEffect, useState} from "react";
import project_1 from "../../images/project1.jpg";
import red_play from "../../images/red-play.png";
import axios from "axios";
import {baseURL, feUrl} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import {CopyToClipboard} from 'react-copy-to-clipboard';


const ProjectCard = ({project, fetchCampaign, playLists, type, playersData}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);

    const [loadEdit, setLoadEdit] = useState(false);
    const [modelStatus, setModelStatus] = useState(false);
    const [copStatus, setCopStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [playListId, setPlaylistId] = useState('');

    const [buttonText, setButtonText] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [codeModel, setCodeModel] = useState("none");
    const [copyScript, setCopyScript] = useState(false);
    const [copyEmbed, setCopyEmbed] = useState(false);
    const [copyEmail, setCopyEmail] = useState(false);
    const [playerId, setPlayerId] = useState(false);

    const openDeletePopUp = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }

    const openCodePopup = () => {
        if (codeModel === "none") {
            setCodeModel("block")
            console.log(codeModel);
        } else {
            setCodeModel("none");
            console.log(codeModel);

        }
    }
    const closeCode = () => {
        if (codeModel === "none") {
            setCodeModel("block")
            console.log(codeModel);
        } else {
            setCodeModel("none");
            console.log(codeModel);

        }
    }

    const deleteCampaign = () => {
        setButtonText('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-campaign`,
            data: {id: deleteId, player_type: type},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                fetchCampaign();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButtonText('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButtonText('Delete');
        })
    }


    const editCampaign = async (id) => {
        setLoadEdit(true);
        if (type === "ab_testing") {
            // await axios({
            //     method: "POST",
            //     url: `${baseURL}fetch-campaign`,
            //     data: {id: id, player_type: 'ab'},
            //     headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
            // }).then(res => {
            //     if (res.data.status === true) {
            //         dispatch({type: "UPLOAD_VIDEO", payload: res.data.data});
            //     }
            //     setLoadEdit(false);
            // }).catch(err => {
            //     setLoadEdit(false);
            // })

            history.push(`ab-test?id=${id}`);

        } else {
            await axios({
                method: "POST",
                url: `${baseURL}fetch-campaign`,
                data: {id: id},
                headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
            }).then(res => {
                if (res.data.status === true) {
                    dispatch({type: "UPLOAD_VIDEO", payload: res.data.data});
                }
                setLoadEdit(false);
            }).catch(err => {
                setLoadEdit(false);
            })

            history.push(`editor?id=${id}`);
        }


    }

    const handleAbPlayer = async (id) => {
        setLoadEdit(true);

        await axios({
            method: "POST",
            url: `${baseURL}add-ab-testing`,
            data: {id: id},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                history.push(`/ab-testing?id=${id}`)
            }
            setLoadEdit(false);
        }).catch(err => {
            setLoadEdit(false);
        })

    }


    const handleClonePlayer = async (id) => {
        setLoadEdit(true);

        await axios({
            method: "POST",
            url: `${baseURL}clone-player`,
            data: {id: id},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch({type: "UPLOAD_VIDEO", payload: res.data.data});
            }
            setLoadEdit(false);
        }).catch(err => {
            setLoadEdit(false);
        })

        history.push(`editor?id=${id}`);
    }

    const handleAddPlaylist = (e) => {
        e.preventDefault();
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}add-to-playlist`,
            data: {id: playListId, player_id: project.id},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }

            setModelStatus(false);
            setLoader(false);
        }).catch(err => {
            setModelStatus(false);
            setLoader(false);
        })
    }

    useEffect(()=>{
       if(playersData!==undefined && playersData !== ''){
           setPlayerId(JSON.parse(playersData)[0].player_id);
           console.log(JSON.parse(playersData)[0].player_id);
       }
    },[])

    return (
        <>
            <Alert/>
            <div className="col-12 col-md-6 col-lg-3 mb-4 my-video-projects-box">
                <div className="card">
                    <div className="card-box">
                        {
                            type === 'ab_testing' ?
                                <img className="card-img-top" src={project_1}
                                     alt="Voice Projects"/>
                                : <img className="card-img-top"
                                       src={project.thumbnail !== "" ? project.thumbnail : project_1}
                                       alt="Voice Projects"/>

                        }

                        <div className="card-box-overlay">
                            <p className="card-box-date">{project.created}</p>
                            {
                                type !== "ab_testing" && auth.user.is_client_account === "0" ?
                                    <div className="video-dropdown dropdown">
                                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                                id="dropdownVideoButton"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <ul className="dropdown-menu"
                                            aria-labelledby="dropdownVideoButton">
                                            <li>
                                                <a onClick={() => setModelStatus(true)}
                                                   className="dropdown-item cursor-pointer">Add to Playlist</a>
                                            </li>
                                            <li>
                                                <a onClick={() => handleClonePlayer(project.id)}
                                                   className="dropdown-item cursor-pointer">Clone Player</a>
                                            </li>
                                            <li>
                                                <Link to={`/analytics?id=${project.id}`}
                                                      className="dropdown-item cursor-pointer">Analytic</Link>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <a onClick={()=>handleAbPlayer(project.id)} className="dropdown-item cursor-pointer">A/B Testing</a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                    : ''
                            }
                            {
                                type === 'ab_testing' ?
                                    <div className="video-dropdown dropdown">
                                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                                id="dropdownVideoButton"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <ul className="dropdown-menu"
                                            aria-labelledby="dropdownVideoButton">
                                            <li>
                                                <Link to={`/analytics?ab_id=${project.id}`}
                                                      className="dropdown-item cursor-pointer">Analytic</Link>
                                            </li>
                                        </ul>
                                    </div>
                                : ''

                            }
                            <ul className="card-box-edit-more">
                                {
                                    auth.user.is_client_account === "0" ?
                                        <li>
                                            <a onClick={() => editCampaign(project.id)}>
                                                <i className="fa fa-pencil-square-o cursor-pointer"></i>
                                                <p>Edit</p>
                                            </a>
                                        </li>
                                        : ''
                                }
                                <li>
                                    <a className="cursor-pointer" onClick={() => setCopStatus(true)}>
                                        <i className="fa fa-file-code-o"></i>
                                        <p>Get Code</p>
                                    </a>
                                </li>
                                {
                                    auth.user.is_client_account === "0" ?
                                        <li>
                                            <a className="cursor-pointer" onClick={() => openDeletePopUp(project.id)}>
                                                <i className="fa fa-trash"></i>
                                                <p>Delete</p>
                                            </a>
                                        </li>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="card-body my-video-projects-box-details">

                        <div className="video-title">
                            {
                                type === 'ab_testing' ?
                                    <p className="card-text">{project.name}</p>
                                    : <p className="card-text">{project.campaign}</p>
                            }

                        </div>
                    </div>
                </div>
            </div>

            {
                loadEdit ?
                    <div className="loader-sec">
                        <div className="loader">
                        </div>
                    </div>
                    : ''
            }
            <div className={`modal ${copStatus ? 'show-model' : ''}`}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" id="model_embed"
                         style={{width: "500px", color: '#FF8F4B', margin: 'auto'}}>
                        <div className="modal-header mb-4">
                            <h4 style={{fontSize: '18px'}} className="modal-title">Player Integration Info</h4>
                            <button onClick={() => setCopStatus(false)} type="button" className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/>
                                </span>
                            </button>
                        </div>
                        <h4 style={{fontSize: '14px'}} className="modal-title">Embed Code:</h4>
                        <textarea rows="3"
                                  className="form-control">{`<script src="https://backend.playerneos.com/players/${auth.user.id}/${project.id}.js" type="text/javascript"></script>`}</textarea>
                        <div className="text-right mt-2">
                            <CopyToClipboard
                                text={`<script src="https://backend.playerneos.com/players/${auth.user.id}/${project.id}.js" type="text/javascript"></script>`}
                                onCopy={() => setCopyScript(true)}
                            >
                                <button disabled={loader} type="submit" style={{margin: "0px", color: '#fff'}}
                                        className="btn btn-link btn-gradient-orange border-radius-circle" role="button">
                                    {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}
                                    {copyScript ? 'Copied' : 'Copy'}
                                </button>
                            </CopyToClipboard>
                        </div>
                        <h4 style={{fontSize: '14px'}} className="modal-title">Player URl:</h4>
                        {
                            type === 'ab_testing' ?
                                <>
                                    {
                                        playerId ?
                                            <textarea className="form-control">
                                        {`${feUrl}embed/${playerId}?split_id=${project.id}`}
                                    </textarea> : ''
                                    }
                                    <div className="text-right mt-2">
                                        <CopyToClipboard text={`${feUrl}embed/${playerId}?split_id=${project.id}`}
                                                         onCopy={() => setCopyEmbed(true)}>
                                            <button disabled={loader} type="submit"
                                                    style={{margin: "0px", color: '#fff'}}
                                                    className="btn btn-link btn-gradient-orange border-radius-circle"
                                                    role="button">
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}
                                                {copyEmbed ? 'Copied' : 'Copy'}
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                :
                                <>
                                <textarea className="form-control">
                                        {`${feUrl}embed/${project.id}`}
                                    </textarea>
                                    <div className="text-right mt-2">
                                        <CopyToClipboard text={`${feUrl}embed/${project.id}`}
                                                         onCopy={() => setCopyEmbed(true)}>
                                            <button disabled={loader} type="submit"
                                                    style={{margin: "0px", color: '#fff'}}
                                                    className="btn btn-link btn-gradient-orange border-radius-circle"
                                                    role="button">
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}
                                                {copyEmbed ? 'Copied' : 'Copy'}
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </>
                        }
                        {
                            project.embed_type === "email" ?
                                <>
                                    <h4 style={{fontSize: '14px'}} className="modal-title">Email Code:</h4>
                                    <textarea className="form-control">
                                        {`<div style="width: 100%; margin: 0 auto;"> <div style="background: #000; box-sizing: border-box; position: relative; padding-bottom: 56.25%; margin: 0 auto; height: 0; text-align: left; margin: 0 auto;"> <iframe style="position: absolute; width: 100%; height: 100%" frameborder="0" scrolling="no" src="https://fe.playerneos.com/embed/${project.id}" allow="autoplay"></iframe></div></div>`}
                                    </textarea>
                                    <div className="text-right mt-2">
                                        <CopyToClipboard
                                            text={`<div style="width: 100%; margin: 0 auto;"> <div style="background: #000; box-sizing: border-box; position: relative; padding-bottom: 56.25%; margin: 0 auto; height: 0; text-align: left; margin: 0 auto;"> <iframe style="position: absolute; width: 100%; height: 100%" frameborder="0" scrolling="no" src="https://fe.playerneos.com/embed/${project.id}" allow="autoplay"></iframe></div></div>`}
                                            onCopy={() => setCopyEmail(true)}>
                                            <button disabled={loader} type="submit"
                                                    style={{margin: "0px", color: '#fff'}}
                                                    className="btn btn-link btn-gradient-orange border-radius-circle"
                                                    role="button">
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}
                                                {copyEmail ? 'Copied' : 'Copy Player URL To Clipboard'}
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                : ''
                        }
                    </div>
                </div>
            </div>

            <div className={`modal ${modelStatus ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{width: "400px", color: '#FF8F4B', margin: 'auto'}}>
                        <div className="modal-header">
                            <h4 style={{fontSize: '18px'}} className="modal-title">Add to Playlist</h4>
                            <button onClick={(e) => setModelStatus(false)} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/>
                                </span>
                            </button>
                        </div>
                        <form method="post" onSubmit={(e) => handleAddPlaylist(e)} className="modal-body">
                            <div className="form-group">
                                <select required className="form-control" aria-label="Default select example"
                                        onChange={(e) => setPlaylistId(e.target.value)}>
                                    <option selected disabled>Select a Playlist</option>
                                    {
                                        playLists.length > 0 ?
                                            playLists.map((item, index) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                            : ''
                                    }
                                </select>
                            </div>
                            <div className="form-group text-right">
                                <button disabled={loader} type="submit" style={{margin: "0px", color: '#fff'}}
                                        className="btn btn-link btn-gradient-orange border-radius-circle" role="button">
                                    {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Save
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div className="videoprojects-modal">
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this campaign
                </SweetAlert>
            </div>
        </>
    )
}

export default ProjectCard;
