import React, {useState} from "react";
import wordpress from "../../images/wordpress.png";
import download from "../../images/download.png";
import importIcon from "../../images/import_icon.png";
import customize from "../../images/customize.png";
import youtubeimg from "../../images/youtubeimg.jpg";
import Rebranding from "./Rebranding";
import Footer from "../Footer";


const UpgradesContent = () => {

    const [playing, setPlaying] = useState(false);
    const [frameUrl, setFrameUrl] = useState();

    const addFrameUrl = () => {
        setPlaying(true);
        setFrameUrl('https://player.vimeo.com/video/514577280?');
    }

    const closePop = () => {
        setPlaying(false);
        setFrameUrl('');
    }

    return (
        <>
        <div className="col-12 col-md-8">
            <div className="upgrades-sec-content">
                <div className="tab-content" id="pills-tabContent">
                    <Rebranding/>
                    <div className="tab-pane fade" id="pills-business-finder" role="tabpanel"
                         aria-labelledby="pills-business-finder-tab">
                        <h5 className="text-white">Business Finder</h5>
                        <div className="upgrade-sec-content-box">
                            <div className="row">
                                <div className="col-md-12 mx-auto">
                                    <iframe
                                        src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                                        style={{ width: "100%", height: "100vh" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-fb-templates" role="tabpanel"
                         aria-labelledby="pills-fb-templates-tab">
                        <h5 className="text-white">FB Templates</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="card">
                                        <h5 className="card-header gradiant-button-pb text-white">Facebook
                                            Ads Templates</h5>
                                        <div className="card-body text-center">
                                            <p className="card-text">Download this done-for-you Facebook
                                                and Instagram ad creatives and ad copy templates that
                                                can easily be customized to be used for any niche</p>
                                        </div>
                                    </div>
                                    <div className="card mt-5">
                                        <div className="card-body text-center">
                                            <p className="card-text">Download your Facebook Ads
                                                Templates here</p>
                                            <a href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                               target="_blank"
                                               className="btn btn-bundle primary-gradient">Download
                                                the Templates</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-acadeable" role="tabpanel"
                         aria-labelledby="pills-acadeable-tab">
                        <h5 className="text-white">Acadeable</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="card">
                                        <h5 className="card-header gradiant-button-pb text-white">Acadeable</h5>
                                        <div className="card-body text-center">
                                            <p className="card-text">Download Udemy Style Website to
                                                showcase your courses. Instant Access to Udemy Style
                                                Website Builder to Sell Courses</p>
                                        </div>
                                    </div>
                                    <div className="card mt-5">
                                        <div className="card-body text-center">
                                            <a href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip"
                                               target="_blank"
                                               className="btn btn-bundle primary-gradient">Download
                                                Setup</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center mt-5">
                                <div className="col-md-12 mt-2">
                                    <h5 className="text-white">Tutorials</h5>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3"><a
                                            href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/How+to+install+%26+setup+Acadeable.mp4"
                                            target="_blank">
                                            <button
                                                className="btn btn-bundle primary-gradient d-block w-100">How
                                                to install &amp; setup Acadeable
                                            </button>
                                        </a
                                        ></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3"><a
                                            href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf"
                                            target="_blank">
                                            <button
                                                className="btn btn-bundle primary-gradient d-block w-100">Installation
                                                with Apache
                                            </button>
                                        </a
                                        ></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3"><a
                                            href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf"
                                            target="_blank">
                                            <button
                                                className="btn btn-bundle primary-gradient d-block w-100">Installing
                                                Acedeable
                                            </button>
                                        </a
                                        ></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3"><a
                                            href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf"
                                            target="_blank">
                                            <button
                                                className="btn btn-bundle primary-gradient d-block w-100">PWA
                                                Setup
                                            </button>
                                        </a
                                        ></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3"><a
                                            href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf"
                                            target="_blank">
                                            <button
                                                className="btn btn-bundle primary-gradient d-block w-100">Server
                                                Requirements
                                            </button>
                                        </a
                                        ></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para d-block p-3 "><a
                                            href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf"
                                            target="_blank">
                                            <button
                                                className="btn btn-bundle primary-gradient d-block w-100">Shared
                                                Hosting Install
                                            </button>
                                        </a
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-client-contact" role="tabpanel"
                         aria-labelledby="pills-client-contact-tab">
                        <h5 className="text-white">Client Contract</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6 className="card-header gradiant-button-pb text-white">Client
                                                Contract</h6>
                                        </div>
                                        <div className="card-body text-center">
                                            <p className="text-center ">
                                                Download our "Client Contract" template. This is a docx file,
                                                works best with MS Word. Edit the doc and add your business
                                                name, address and other details. Fill the empty blank space
                                                with your service, eg Video Marketing, Local Reputation
                                                Management, Social Media Marketing, Design &amp; Creatives,
                                                Ads Management and so on.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row   mt-5">
                                <div className="col-md-4">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-heading">
                                            <h6 className="card-header gradiant-button-pb text-white">Brief Pitch
                                                Template1_2</h6>
                                        </div>
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                                                target="_blank"
                                            >
                                                <button className="btn btn-bundle primary-gradient">Download
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-heading">
                                            <h6 className="card-header gradiant-button-pb text-white">Service
                                                Agreement</h6>
                                        </div>
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                                                target="_blank"
                                            >
                                                <button className="btn btn-bundle primary-gradient">Download
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-heading">
                                            <h6 className="card-header gradiant-button-pb text-white">Meeting Setup
                                                Template1_2</h6>
                                        </div>
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                                                target="_blank"
                                            >
                                                <button className="btn btn-bundle primary-gradient">Download
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-reseller-license" role="tabpanel"
                         aria-labelledby="pills-reseller-license-tab">
                        <h5 className="text-white">Reseller License</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6 className="card-header gradiant-button-pb text-white">Reseller
                                                License</h6>
                                        </div>
                                        <div className="card-body text-center">
                                            <p className="text-center ">
                                                Go to JVZoo Dashboard and request your affiliate link. Once
                                                you've requested, submit a ticket with your PlayerReel Business
                                                transaction ID, affiliate ID and email address for our team to
                                                activate your reseller license. Please allow upto 2 working
                                                days.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row   mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://www.jvzoo.com/affiliate/affiliateinfo/index/363723"
                                                target="_blank"
                                            >
                                                <button className="btn btn-bundle primary-gradient">
                                                    Request Affiliate Link
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a href="https://support.vineasx.com/" target="_blank">
                                                <button className="btn btn-bundle primary-gradient">Raise
                                                    Support Ticket
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-web-hosting" role="tabpanel"
                         aria-labelledby="pills-web-hosting-tab">
                        <h5 className="text-white">Web Hosting</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6 className="card-header gradiant-button-pb text-white">Web
                                                Hosting</h6>
                                        </div>
                                        <div className="card-body text-center">
                                            <p className="text-center ">
                                                Please raise a support ticket with your PlayerReel purchase
                                                transaction ID, your domain name that you currently own and
                                                wish to use with the hosting, email address, your full name
                                                and a note about how you would be using the webhosting for
                                                your business.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a href="https://support.vineasx.com/" target="_blank">
                                                <button className="btn btn-bundle primary-gradient mt-3">Raise a
                                                    Ticket
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-video-commercial" role="tabpanel"
                         aria-labelledby="pills-video-commercial-tab">
                        <h5 className="text-white">Video Commercial</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6 className="card-header gradiant-button-pb text-white">Video
                                                Commercial</h6>
                                        </div>
                                        <div className="card-body text-center">
                                            <p className="text-center ">
                                                6X Stunning &amp; Compelling Video Commercials
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/6X+Stunning+%26+Compelling+Video+Commercials+.zip"
                                                target="_blank"
                                            >
                                                <button className="btn btn-bundle primary-gradient">Download
                                                    Videos
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-dfy-lead-magnets" role="tabpanel"
                         aria-labelledby="pills-dfy-lead-magnets-tab">
                        <h5 className="text-white">DFY Lead Magnets</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="upgrade-box-content  text-white">
                                    <div className="upgrade-heading mt-4">
                                        <h6 className="card-header gradiant-button-pb text-white">DFY Lead
                                            Magnets</h6>
                                    </div>
                                    <div className="row d-flex mt-5 mb-5 justify-content-center">
                                        <div className="col-md-5">
                                            <div className="upgrade-box-content text-center  text-white">
                                                <div className="upgrade-para p-3">
                                                    <a
                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                                                        target="_blank"

                                                    >
                                                        <button
                                                            className="btn btn-bundle primary-gradient mt-3">Download

                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="tab-pane fade" id="pills-agency-website" role="tabpanel"
                         aria-labelledby="pills-agency-website-tab">
                        <h5 className="text-white">Agency</h5>
                        <div className="upgrades-sec-content-box">
                            <div className="row">
                                <div className="col-md-10 mx-auto">
                                    <div className="upgrade-box-content p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-lg-2 col-12 pr-0 text-center ">
                                                <img src={wordpress} alt="wprdpress" className="mb-3"/>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Fresh Install and configure wordpress at your server. If
                                                        you need help in installing and configuring wordpress,
                                                        Click here to find the tutorial.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-lg-2 col-12 pr-0 text-center ">
                                                <img src={download} alt="download" className="mb-3"/>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Once wordpress is installed at your server. Download the
                                                        site content by clicking the "Download site button". This
                                                        will download a wpress file which you will be needing to
                                                        import in your wordpress site.
                                                    </p>
                                                    <div className="row  d-flex justify-content-center">
                                                        <div className="col-md-10">
                                                            <div
                                                                className="upgrade-box-content text-center  text-white">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                                                        target="_blank"
                                                                    >
                                                                        <button
                                                                            className="btn btn-bundle primary-gradient">
                                                                            Download the site
                                                                        </button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-lg-2 col-12 pr-0 text-center ">
                                                <img src={importIcon} alt="import" className="mb-3"/>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Import the xml file in to your wordpress site. If you need
                                                        help importing the xml file in wordpress, Click here to
                                                        find the tutorial.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-lg-2 col-12 pr-0 text-center ">
                                                <img src={customize} alt="customize" className="mb-3"/>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Customize the site as according to your need. If you need
                                                        help customizing the site. Click here to find the tutorial
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="mt-3 text-white">Tutorial</h5>
                        <div className="upgrade-content tutorial mt-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="upgrade-box-content p-2 text-white">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="tutorial-content text-md-left text-center">
                                                    <div className="row">
                                                        <div className="col-md-2 col-12">
                                                            <img src={wordpress} alt="wordpress"/>
                                                        </div>
                                                        <div
                                                            className="col-md-10 col-12 d-md-flex d-inline align-items-center">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button
                                                                        title="Install WordPress From cPanel"
                                                                        className="btn btn-bundle primary-gradient"
                                                                    >
                                                                        Install WordPress From c...
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <img src={importIcon}/>
                                                        </div>
                                                        <div
                                                            className="col-md-10 col-12 d-md-flex d-inline align-items-center">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button
                                                                        className="btn btn-bundle primary-gradient mt-3">
                                                                        Install Plugins
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <img src={customize}/>
                                                        </div>
                                                        <div
                                                            className="col-md-10 col-12 d-md-flex d-inline align-items-center">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button
                                                                        className="btn btn-bundle primary-gradient">
                                                                        Customize website
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <img src={download}/>
                                                        </div>
                                                        <div
                                                            className="col-md-10 col-12 d-md-flex d-inline align-items-center">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button
                                                                        className="btn btn-bundle primary-gradient mt-3"
                                                                        title="Upload Video and Usage in Agency Website"
                                                                    >
                                                                        Upload Video and Usa...
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div onClick={(e) => addFrameUrl()} style={{
                                                            cursor: "pointer",
                                                            color: '#007bff'
                                                        }} data-toggle="modal" data-target="#MyModal">
                                                    <div className="card fb-card border-0 mt-3">
                                                        <div
                                                            className="card-header text-center"
                                                            style={{background: "rgb(0, 123, 255)"}}
                                                        >
                                                            <h6 className="text-white Account-Management-headings">
                                                                How to Install the Agency Website
                                                            </h6>
                                                        </div>
                                                        <div className="card-body p-0">
                                                            <img src={youtubeimg} alt=""
                                                                className="img-fluid"/>
                                                                <span className="youtubeicon"><i
                                                                className="fas fa-play-circle"/></span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                        className={`modal ${playing ? 'show-modal' : ''} `}>
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"/>
                                                                    <button type="button"
                                                                            onClick={(e) => closePop('')}
                                                                            className="close"
                                                                            data-dismiss="modal"
                                                                            aria-label="Close">
                                                        <span aria-hidden="true">
                                                            <i className="fas fa-times-circle" style={{color: "#ffffff"}}/></span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div
                                                                        className="embed-responsive embed-responsive-16by9">
                                                                        <iframe id="frameSrc"
                                                                                className="embed-responsive-item"
                                                                                src={frameUrl}
                                                                                allowFullScreen=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default UpgradesContent;
