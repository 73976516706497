import React, {useEffect} from "react";
import gDrive_icon from "../../images/GDRIVE (Disable).png";
import gDrive_enable from "../../images/GDRIVE (Default).png";
import drop_icon from "../../images/DROPBOX (Disable).png";
import drop_enable from "../../images/DROPBOX (Default).png";
import thumb_icon from "../../images/THUMBREEL (Disable).png";
import thumb_enable from "../../images/THUMBREEL (Default).png";
import video_icon from "../../images/VIDEOREEL (Disable).png";
import video_enable from "../../images/VIDEOREEL (Default).png";
import story_icon from "../../images/STORYREEL (Disable).png";
import story_enable from "../../images/STORYREEL (Default).png";
import clips_icon from "../../images/CLIPSREEL (Disable).png";
import clips_enable from "../../images/CLIPSREEL (Default) .png";
import amazon_icon from "../../images/AMAZON (Disable).png";
import amazon_enable from "../../images/AMAZON (Default).png";
import ar_enable from "../../images/AUTO RESPONDER (Default).png";
import ar_icon from "../../images/AUTO RESPONDER (Disable).png";
import {HashLink as Link} from 'react-router-hash-link';
import {useDispatch, useSelector} from "react-redux";
import {fetchSocialAccounts} from "../../actions/socialAction";

const IntegrationHead = () => {

    const dispatch = useDispatch();

    const socialData = useSelector(state => state.integrate);

    useEffect(() => {
        dispatch(fetchSocialAccounts());
    }, [])

    return (
        <>
            <ul className="integration-box list-inline d-flex  flex-wrap justify-content-center">
                <li className="hvr-grow">
                    <a href="#gdrive" scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}>

                    <div className="img-box">
                            <img src={socialData.gDrive.length > 0 ? gDrive_enable : gDrive_icon} alt="g-drive"/>
                        </div>
                        <p className="mt-2">G-Drive</p>
                    </a>
                </li>
                {/*<li className="hvr-grow">*/}
                {/*    <a href="#dropbox">*/}
                {/*        <div className="img-box">*/}
                {/*            <img src={socialData.dropdata.length > 0 ? drop_enable : drop_icon} alt="dropbox"/>*/}
                {/*        </div>*/}
                {/*        <p className="mt-2">DropBox</p>*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li className="hvr-grow">
                    <a href="#aws" scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}>

                    <div className="img-box">
                            <img src={socialData.amazondata.length > 0 ? amazon_enable : amazon_icon} alt="ClipsReel"/>
                        </div>
                        <p className="mt-2">Amazon</p>
                    </a>
                </li>
                <li className="hvr-grow">
                    <a href="#thumbreel" scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}>
                    <div className="img-box">

                            <img src={socialData.thumbdata.length > 0 ? thumb_enable : thumb_icon} alt="Thumb"/>
                        </div>
                        <p className="mt-2">ThumbReel</p>
                    </a>
                </li>
                <li className="hvr-grow">
                    <a href="#videoreel" scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}>

                    <div className="img-box">
                            <img src={socialData.videodata.length > 0 ? video_enable : video_icon} alt="VideoReel"/>
                        </div>
                        <p className="mt-2">VideoReel</p>
                    </a>
                </li>
                <li className="hvr-grow">
                    <a href="#storyreel" scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}>
                        <div className="img-box">
                            <img src={socialData.storydata.length > 0 ? story_enable : story_icon} alt="StoryReel"/>
                        </div>
                        <p className="mt-2">StoryReel</p>
                    </a>

                </li>
                <li className="hvr-grow">
                    <a href="#clipsreel" scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}
                    >
                        <div className="img-box">
                            <img src={socialData.clipsdata.length > 0 ? clips_enable : clips_icon} alt="ClipsReel"/>
                        </div>
                        <p className="mt-2">ClipsReel</p>
                    </a>

                </li>
                <li className="hvr-grow">
                    <a href="#autoresponder" scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}>
                        <div className="img-box">
                            <img src={socialData.responderData.length > 0 ? ar_enable : ar_icon} alt="autoresponder"/>
                        </div>
                        <p className="mt-2">AR</p></a>
                    {/*<a>
                        <div className="img-box">
                            <img src={socialData.responderData.length > 0 ? ar_enable : ar_icon} alt="autoresponder"/>
                        </div>
                        <p className="mt-2">AR</p>
                    </a>*/}
                </li>

            </ul>
        </>
    )
}

export default IntegrationHead;
