import React from "react";
import Navbar from "../Navbar";
import AddAccount from "./AddAccount";
import Footer from "../Footer";
import Alert from "../Alert";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";

const AccountManagement = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Account Management </title>
            </Helmet>
             <Navbar/>
            <section className="account-management">
                <div className="container">
                        <AddAccount/>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default AccountManagement;
