import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {updatePassword} from "../../actions/authAction";

const ProfilePassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    });

    const handleInputChange = (e) => {
        setUserDetails({...userDetails, [e.target.name] : e.target.value});
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(updatePassword(userDetails, setLoader));
    }

    return(
        <>
            {/*<div className="container text-left mt-4">
                <h4>Update Password</h4>
                <div className="row">
                    <div className="col-md-6">
                        <form method="post" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Current Password</label>
                                <input type="password" className="form-control" placeholder="Enter Current Password" name="password" required onChange={(e)=>handleInputChange(e)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">New Password</label>
                                <input type="password" className="form-control" placeholder="Enter New Password" name="new_password" required onChange={(e)=>handleInputChange(e)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Confirm Password</label>
                                <input type="password" className="form-control" placeholder="Enter Confirm Password" name="confirm_password" required onChange={(e)=>handleInputChange(e)} />
                            </div>

                            <button type="submit" className="btn btn-primary">Update</button>

                        </form>
                    </div>
                </div>
            </div>*/}

            <div className="personal-details-box mt-5">
                <form className="personal-details-box-form" method="post" onSubmit={handleFormSubmit}>
                    <h6 className="mb-5 text-center">Reset Password</h6>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group mb-sm-4 mb-3">
                                <small className="form-text text-muted">Current Password</small>
                                <input type="password" className="form-control"
                                       placeholder="******************" name="password" required onChange={(e)=>handleInputChange(e)}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mb-3">
                                <small className="form-text text-muted">New Password</small>
                                <input type="password" className="form-control"
                                       placeholder="******************" name="new_password" required onChange={(e)=>handleInputChange(e)}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mb-3">
                                <small className="form-text text-muted">Confirm Password </small>
                                <input type="password" className="form-control"
                                       placeholder="******************" name="confirm_password" required onChange={(e)=>handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn gradiant-button-pb btn-submit mt-4">
                        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update

                    </button>
                </form>
            </div>
        </>
    )
}

export default ProfilePassword;
