const initialState = {
    gDrive:[],
    dropdata: [],
    thumbdata: [],
    videodata: [],
    storydata: [],
    clipsdata: [],
    amazondata: [],
    responderData: []
 }

export default function (state= initialState, action){
    switch (action.type) {
        case 'SAVE_INTEGRATION':
        return {
            ...state,
            gDrive: action.payload.data.google_drive,
            dropdata: action.payload.data.dropbox,
            thumbdata: action.payload.data.thumbreel,
            videodata: action.payload.data.videoreel,
            storydata: action.payload.data.storyreel,
            clipsdata: action.payload.data.clipsreel,
            amazondata: action.payload.data.amazon,
            responderData: action.payload.data.ar
        }
        /*case 'GET_GDRIVE': return{
            ...state,
            gDrive: action.payload
        }
        case 'GET_DROP': return{
            ...state,
            dropdata: action.payload
        }
        case 'GET_THUMBREEL': return{
            ...state,
            thumbdata: action.payload
        }
        case 'GET_VIDEOREEL': return{
            ...state,
            videodata: action.payload
        }
        case 'GET_STORYREEL': return{
            ...state,
            storydata: action.payload
        }
        case 'GET_CLIPSREEL': return{
            ...state,
            clipsdata: action.payload
        }
        case 'GET_COURSEREEL': return{
            ...state,
            coursedata: action.payload
        }
        case 'GET_SCRIPTREEL': return{
            ...state,
            scriptdata: action.payload
        }*/
        default:
            return state
    }
}
