import React from "react";
import PrivacyEmail from "./PrivacyEmail";
import PrivacyGdpr from "./PrivacyGdpr";
import Navbar from "../Navbar";
import Footer from "../Footer";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";

const Privacy = () => {
    const brandName = useSelector(state => state.rebrand.data);
    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Privacy </title>
            </Helmet>
            <Navbar/>
            <div className="privacy-setting-box">
                <PrivacyEmail/>
                <PrivacyGdpr/>
            </div>
            <Footer/>
        </>
    )
}

export default Privacy;
