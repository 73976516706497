import next_icon from "../../images/next.png";

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseURL} from "../../global/global";
import ProjectCard from "../projects/ProjectCard";
import {useSelector} from "react-redux";

const DashboardContent = ()=>{

    const auth = useSelector(state => state.auth);
    const [projects , setProjects] = useState([]);
    const [projectLoad, setLoadProject] = useState(false);
    const [playLists, setPlayLists] = useState([]);

    const fetchProjects = () => {
        setLoadProject(true);

        axios({
            method: 'POST',
            url: `${baseURL}fetch-campaigns`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token }
        }).then(res=>{
            if(res.data.status===true){
                setProjects(res.data.data);
            }
            setLoadProject(false);
        }).catch(err=>{
            setLoadProject(false);
        })
    }

    const fetchPlaylist = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-playlist`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res=>{
            if(res.data.status === true){
                setPlayLists(res.data.data);
            }
        }).catch(err=>{

        })
    }

    useEffect(()=>{
        fetchProjects();
        fetchPlaylist();
    },[])

    return(
        <>
            <section id="my-video-projects">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 col-md-6">
                            <div className="title">
                                <h2>Video <span className="red">Projects</span></h2>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="view-all">
                                <Link to="/my-players" className="gradiant-button-pb view-all">
                                    View All Projects
                                    <img src={next_icon} alt="Play icon" className="play-icon"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="my-video-projects">
                        <div className="row gx-4">

                            {
                                projects.length > 0 ?
                                    projects.slice(0, 6).map((item,index)=>(
                                        <ProjectCard key={item.id} project={item} fetchCampaign={fetchProjects} playLists={playLists} />
                                    ))
                                    : <h5 className="text-center">You have not created player yet</h5>
                            }
                        </div>
                    </div>
                </div>
            </section>
            </>
    )
}
export default DashboardContent;
