import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const UpgradesMenu = () => {
    const auth = useSelector(state => state.auth);
    const [membership, setMembership] = useState([]);

    useEffect(()=>{
        if(auth.user.membership !==''){
            setMembership(auth.user.membership.split('__'))
        }
    },[])

    return (
        <>
            <div className="col-12 col-md-4">
                <div className="upgrades-sec-head">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-agency-rebranding-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-agency-rebranding"
                                    type="button" role="tab" aria-controls="pills-agency-rebranding"
                                    aria-selected="true">Agency Rebranding
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-business-finder-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-business-finder"
                                    type="button" role="tab" aria-controls="pills-business-finder"
                                    aria-selected="false">Business Finder
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-fb-templates-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-fb-templates" type="button" role="tab"
                                    aria-controls="pills-fb-templates" aria-selected="false">FB Templates
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-acadeable-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-acadeable" type="button" role="tab"
                                    aria-controls="pills-acadeable" aria-selected="false">Acadeable
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-client-contact-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-client-contact" type="button" role="tab"
                                    aria-controls="pills-client-contact" aria-selected="false">Client
                                Contract
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-reseller-license-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-reseller-license"
                                    type="button" role="tab" aria-controls="pills-reseller-license"
                                    aria-selected="false">Reseller License
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-web-hosting-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-web-hosting" type="button" role="tab"
                                    aria-controls="pills-web-hosting" aria-selected="false">Web Hosting
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-video-commercial-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-video-commercial"
                                    type="button" role="tab" aria-controls="pills-video-commercial"
                                    aria-selected="false">Video Commercial
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-dfy-lead-magnets-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-dfy-lead-magnets"
                                    type="button" role="tab" aria-controls="pills-dfy-lead-magnets"
                                    aria-selected="false">DFY Lead Magnets
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-agency-website-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-agency-website" type="button" role="tab"
                                    aria-controls="pills-agency-website" aria-selected="false">Agency
                                Website
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default UpgradesMenu;
