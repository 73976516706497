import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {forgetPassword} from "../../actions/authAction";
import Footer from "../Footer";
import Header from "./Header";
import {Helmet} from "react-helmet";
import Alert from "../Alert";

const ForgetPassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: ''
    })

    const handleInputChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(forgetPassword(user, setLoader))
    }

    return (
        <>
            <Helmet>
                <title> PlayerReel | Forget Password </title>
            </Helmet>

            <div className="login-sec">
                <Header/>
                <Alert/>
                <section id="login-page" className="login-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <div className="login-content text-center">
                                    <h1>Welcome <span>Back !</span></h1>
                                    <p>Turn any video or video URL into a beautifully customized video player in seconds. Watch PlayerReel instantly create a unique, traffic-getting videos player that you and your clients will love.</p>
                                    {/*<h5 className="mt-3">…It’s Super Easy-To-Use</h5>*/}
                                </div>

                            </div>
                            <div className="col-12 col-md-6">
                                <form className="login-form" onSubmit={(e) => handleFormSubmit(e)}>
                                    <h4 className="mb-4"><span className="highlight red">Enter</span> Your Email Address
                                    </h4>
                                    <div className="mb-4">
                                        <label htmlFor="InputEmail" className="form-label">Email Address</label>
                                        <input type="email" className="form-control" id="InputEmail" required
                                               aria-describedby="emailHelp" placeholder="Enter Email" name='email'
                                               onChange={(e) => handleInputChange(e)}/>
                                    </div>

                                    <button type="submit" className="btn gradiant-button-pb btn-submit"
                                            disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Submit

                                    </button>
                                    <p className="forgot-account mt-4">Have a Login ?
                                        <Link to="/login"> Click here</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
            </>
            )
            }

            export default ForgetPassword;
