import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {useDispatch, useSelector} from "react-redux";
import {addRebrandData, removeRebrandData} from "../../actions/rebrandAction";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";

const Rebranding = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);

    const [fileData, setFileData] = useState('');
    const [name, setName] = useState('');

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [deleteData, setDeletedData] = useState({
        id: ''
    });
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = async () => {
        setButton('Deleting...')
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }

        await axios.post(`${baseURL}delete-wl`, deleteData, options).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(removeRebrandData(auth.token))
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })

        setShowDelete(false);
        setButton('Delete')
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const removeWl = (id) => {
        setDeletedData({...deleteData, id: id});
        setShowDelete(true);
    }

    const onInputFile = (e) => {
        setFileData(e.target.files[0]);
    }

    const onInputName = (e) => {
        setName(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
            if (fileData.size < 5000000) {
                setLoader(true);

                let formData = new FormData();
                formData.append('name', name);
                formData.append('logo', fileData);

                dispatch(addRebrandData(formData, setLoader));

            } else {
                swal("Oops!", "Max Uploaded Size for Image 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
        setName("")
    }


    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData]);

    console.log(fileData)

    return (
        <>
            <Alert/>
            <div className="tab-pane fade show active" id="pills-agency-rebranding"
                 role="tabpanel" aria-labelledby="pills-agency-rebranding-tab">
                <h5 className="text-white">Rebranding</h5>
                <div className="upgrades-sec-content-box rebranding-box">
                    <div>
                        <form encType="multipart/form-data" className="thumbnail-form" method="post"
                              onSubmit={(e) => onFormSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="dropzone-wrapper" style={{bottom: "25px"}}>
                                            <div className="dropzone-desc">
                                                <p>Upload your brand logo (JPG, PNG, JPEG upto
                                                    5MB is allowed) <br/> (210x34) Pixels
                                                    recommended</p><i style={{position:"relative", bottom: "15px"}}
                                                className="fa fa-cloud-upload"
                                                aria-hidden="true"/>
                                            </div>
                                            <input type="file"
                                                   className="custom-file-input"
                                                   id="customFile"
                                                   name="file"
                                                   required
                                                   onChange={(e) => onInputFile(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col-md-6 col-12 mt-4 p-1 mx-auto">
                                    <div className="form-group form-group-mb">
                                        <label className="input-label text-white ">Brand
                                            Name </label>
                                        <input
                                            className="form-control input-dark-accnt personal-info"
                                            style={{color: "#ffffff"}}
                                            type="text"
                                            name="firstName"
                                            placeholder="Enter your brand Name"
                                            required
                                            onChange={(e) => onInputName(e)}/>
                                        <button type="submit"
                                                className="btn gradiant-button-pb btn-submit mt-4">
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}Update

                                        </button>
                                    </div>
                                    {
                                        rebrand.data !== false ?
                                            <button style={{
                                                color: "#fff",
                                                padding: "10px 30px",
                                                border: "none",
                                                borderRadius: 30,
                                                width: "auto"
                                            }} type="button" onClick={(e) => removeWl(rebrand.data.id)}
                                                    className="btn gradiant-button-pb btn-submit mt-4">
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}Remove
                                                Rebranding

                                            </button>
                                            : ''
                                    }
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this Rebranding</SweetAlert>
        </>
    )
}
export default Rebranding;
