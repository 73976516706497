import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import DropboxChooser from "react-dropbox-chooser"
import {reelApp} from "../actions/integrateAction";
import Navbar from "./Navbar";
import useDrivePicker from 'react-google-drive-picker'



const Connectapp = ()=>{
    const dispatch = useDispatch();
    const appData = useSelector(state=>state.integrate);
    console.log(appData);

    const [url ,setUrl] = useState('');
    const [type, setType] = useState('')
    const [show, setShow] = useState('none');
    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const getOpen =(value, type)=>{
        setUrl(value);
        setType(type)
        setShow('block')
    }


    const handleInputChange = (e)=>{
    setUser({...user, [e.target.name]: e.target.value});
    }

    const getAppData = (e)=>{
        e.preventDefault();
        dispatch(reelApp(user,url, type, appData))
     }

     const onSuccess = (files)=>{
        console.log(files)
     }

    const [openPicker, data, authResponse] = useDrivePicker();
    // const customViewsArray = [new google.picker.DocsView()]; // custom view
    const handleOpenPicker = () => {
        openPicker({
            clientId: "385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com",
            developerKey: "",
            viewId: "DOCS_IMAGES_AND_VIDEOS",
            // token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            // customViews: customViewsArray, // custom view
        })
    }

    useEffect(() => {
        // do anything with the selected/uploaded files
        if(data){
            data.docs.map(i => console.log(i))
        }
    }, [data])

    return(
        <>
            <Navbar />

             <button onClick={()=>getOpen('https://app.thumbreel.io/fetch-app-thumbnail', "GET_THUMBREEL")}>ThumbReel</button>
            <button onClick={()=>getOpen('https://v2server.videoreel.io/fetch-app-thumbnail', "GET_VIDEOREEL")}>VideoReel</button>
            <button onClick={()=>getOpen('https://v2.storyreel.io/fetch-app-thumbnail', "GET_STORYREEL")}>StoryReel</button>
            <button onClick={()=>getOpen('https://backend.clipsreel.io/fetch-app-thumbnail', "GET_CLIPSREEL")}>ClipsReel</button>
            <button onClick={()=>getOpen('https://coursereelserver.reelapps.io/fetch-app-thumbnail', "GET_COURSEREEL")}>CourseReel</button>
            <button onClick={()=>getOpen('https://app.scriptreel.io/fetch-app-thumbnail', "GET_SCRIPTREEL")}>ScriptReel</button>
            <DropboxChooser
                appKey={'0xb40259dr8kwnq'}
                success={files => onSuccess(files)}
                cancel
                multiselect={true}
                extensions={['.mp4']} >
                <button className="dropbox-button">Dropbox</button>
            </DropboxChooser>
            <button onClick={() => handleOpenPicker()}>G-Drive</button>


            <form onSubmit={getAppData} style={{display: show, margin: '30px'}}>
              <input type='email' name='email' onChange={(e)=>handleInputChange(e)} placeholder='email'/> <br/>
              <input type="password" name='password' onChange={(e)=>handleInputChange(e)} placeholder='password'/> <br/>
              <button type='submit'>Connect</button>
            </form>


          </>

    )
}

export default Connectapp;
