import axios from "axios";
import {setAlert} from "./alert";
import {fetchSocialAccounts} from "./socialAction";

export const reelApp = (data, url, type, setLoader, setShowModal) => (dispatch) => {
    let formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    axios({
        method: 'POST',
        url: `${url}`,
        data: formData,
        headers: {'Content-Type': 'application/json'}

    }).then(res => {
        if (res.data.status === true) {
            dispatch({type: `${type}`, payload: res.data.data});
            const appName = `${type}`.slice(4, type.length);

            dispatch(saveIntegration(appName, res.data.token, data.email, res.data.data));
            dispatch(setAlert(`${appName} connected successfully`, 'success'));
            setShowModal(false);
        } else {
            dispatch(setAlert(res.data.message,'danger'));
        }
        setLoader(false);

    }).catch(err => {
        setLoader(false);
        setShowModal(true);
    })
}

export const saveIntegration = (type, token, email, data) => (dispatch, getState) => {
    axios({
        method: "POST",
        url: 'https://backend.playerneos.com/save-integration',
        data: JSON.stringify({email: email, app_name: type, token: token, data: data}),
        headers: {'Content-Type': 'application/json', 'Authorization': getState().auth.token}
    }).then(res => {
        if (res.data.status === true) {
            dispatch(fetchSocialAccounts());

        }
    })
}


export const saveReelAppsIntegration = (data) => (dispatch, getState) => {
    dispatch({type: 'SAVE_INTEGRATION', payload: data})
}


/*
export const videoreelApp = (data)=>(dispatch)=>{
    axios({
        method: 'POST',
        url: `${baseURL}`,
        data:data,
        headers: {'Content-Type': 'application/json'}
    }).then(res=>{
        if(res.data.status===true){
            dispatch({type: 'GET_VIDEOREEL', dispatch: res.data.data});
        }else{
            console.log(res.data.message)
        }
    }).catch(err=>{
        console.log(err)
    })


}

export const storyreelApp = (data)=>(dispatch)=>{
axios({
    method: 'POST',
    url: `${baseURL}`,
    data: data,
    headers: {'Content-type': 'application/json'}
}).then(res=>{
    if(res.data.status===true){
        dispatch({type: "GET_STORYREEL", payload: res.data.data});
    }else{
        console.log(res.data.message);
    }
})
}

export const clipsreelApp = (data)=>(dispatch)=>{
axios({
    method: "POST",
    url: `${baseURL}`,
    data: data,
    headers: {'Content-type': 'application/json'}
}).then(res=>{
    if(res.data.status===true){
        dispatch({type: "GET_CLIPSREEL", payload: res.data.data})
    }else{
        console.log(res.data.message)
    }
}).catch(err=>{
    console.log(err)
})
}

export const coursereelApp = (data)=>(dispatch)=>{
axios({
    method: 'POST',
    url: `${baseURL}`,
    data: data,
    headers: {'Content-type': 'application/json'}
}).then(res=>{
    if(res.data.status===true){
        dispatch({type: 'GET_COURSEREEL', payload: res.data.data})
    }else{
        console.log(res.data.message)
    }
})
}

export const scriptreelApp = (data)=>(dispatch)=>{
axios({
    method: "POST",
    url: `${baseURL}`,
    data: data,
    headers: {'Content-type': 'application/json'}
}).then(res=>{
    if(res.data.status===true){
        dispatch({type: 'GET_SCRIPTREEL', payload: res.data.data})
    }else{
        console.log(res.data.message)
    }
})
}*/
