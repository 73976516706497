import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DropboxChooser from "react-dropbox-chooser"
import {reelApp} from "../../actions/integrateAction"
import {setAlert} from "../../actions/alert";
import {fetchSocialAccounts} from "../../actions/socialAction";
import {baseURL} from "../../global/global";
import axios from "axios";
import Alert from "../Alert";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import GooglePicker from "react-google-picker";
import randomstring from "randomstring";

import gdrive_disable from "../../images/GDRIVE (Disable).png"
import gdrive_enable from "../../images/GDRIVE (Default).png"
import dropdox_disable from "../../images/DROPBOX (Disable).png";
import dropdox_enable from "../../images/DROPBOX (Default).png";
import thumb_disable from "../../images/THUMBREEL (Disable).png";
import thumb_enable from "../../images/THUMBREEL (Default).png";
import clips_disable from "../../images/CLIPSREEL (Disable).png"
import clips_enable from "../../images/CLIPSREEL (Default) .png";
import story_disable from "../../images/STORYREEL (Disable).png";
import story_enable from "../../images/STORYREEL (Default).png";
import video_disable from "../../images/VIDEOREEL (Disable).png";
import video_enable from "../../images/VIDEOREEL (Default).png";
import amazon_icon from "../../images/AMAZON (Disable).png";
import amazon_enable from "../../images/AMAZON (Default).png";
import ar_enable from "../../images/AUTO RESPONDER (Default).png";
import ar_icon from "../../images/AUTO RESPONDER (Disable).png";
import GoogleLogin from "react-google-login";


const ConnectReelApps = () => {
    const appData = useSelector(state => state.integrate);



    const [url, setUrl] = useState('');
    const [type, setType] = useState('')
    const [show, setShow] = useState('none');
    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [gResponse, setgResponse] = useState();
    const [dropStatus, setDropStatus] = useState();

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [s3Model, setS3Model] = useState(false);
    const [arModel, setArModel] = useState(false);
    const [s3loader, setS3loader] = useState(false);
    const [arloader, setArloader] = useState(false);
    const [s3Data, setS3Data] = useState({
        access_key: '',
        secret_key: '',
        s3_bucket: '',
        s3_region: ''
    })
    const [amazonData, setAmazonData] = useState(false);

    const [optinName, setOptinName] = useState('');
    const [optinCode, setOptinCode] = useState("");
    const [optinEmail, setOptinEmail] = useState('');
    const [optinUrl, setOptinUrl] = useState('');
    const [optinfName, setOptinfName] = useState('');
    const [optinlName, setOptinlName] = useState('');



    const [appType, setAppType] = useState('');
    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user.id
    });

    const [dropData, setDropData] = useState([]);
    const [checkStatus, setCheckStatus] = useState(true);

    // Sweetalert
    const [button, setButton] = useState('Disconnect');
    const [showDelete, setShowDelete] = useState(false);
    const [appname, setAppName] = useState('')
    const [appIcon, setAppIcon] = useState(null)

    const onConfirm = () => {
        setButton('Disconnecting...');
        deleteSocialAccounts();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const deleteIntegration = (id, type = 'integrations') => {
        setIntData({...intData, id: id});
        setAppType(type);
        setShowDelete(true);
    }


    const connectPopUp = (value, type, appName,icon) => {
        setUrl(value);
        setType(type);
        setShowModal(true);
        setAppName(appName)
        setAppIcon(icon)
     }



    const getOpen = (value, type) => {
        setUrl(value);
        setType(type)
        setShow('block')
    }


    const handleInputChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    }

    const handleChangeS3 = (e) => {
        setS3Data({...s3Data, [e.target.name]: e.target.value});
    }

    const connectAmazon = (e) => {
        e.preventDefault();
        setS3loader(true);

        axios({
            method: "POST",
            url: `${baseURL}connect-amazon`,
            data: s3Data,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchAmazon();
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setS3loader(false);
            setS3Model(false);
        }).catch(err => {
            setS3loader(false);
            setS3Model(false);
        })
    }

    const deleteSocialAccounts = () => {

        if (appType !== '') {
            intData.type = appType;
        }

        axios({
            method: "POST",
            url: `${baseURL}delete-integration`,
            data: intData,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(fetchSocialAccounts(auth.token));
                fetchAmazon();
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const getAppData = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(reelApp(user, url, type, setLoader, setShowModal));

    }

    const sendGData = (data) => {
        axios({
            method: "POST",
            url: `${baseURL}save-google-drive`,
            data: {data: data},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(fetchSocialAccounts(auth.token));
                setgResponse(res.status);
                dispatch(setAlert(res.data.message, 'success'));

            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const sendDropData = (data) => {
        axios({
            method: "POST",
            url: `${baseURL}save-dropbox`,
            data: {data: data},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(fetchSocialAccounts(auth.token));
                setDropStatus(res.status);

            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const connectDropbox = () => {
        let url = `${baseURL}connect-dropbox?token=${auth.token}&id=${auth.user.id}&key=${randomstring}`;
        let myWindow = window.open(url, 'mywindow', 'width=500,height=600');


        let interval = setInterval(() => {
            if (checkStatus) {
                axios({
                    method: 'POST',
                    url: `${baseURL}check-network-status`,
                    data: {id: 1},
                    headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
                }).then(res => {

                    if (res.data.status === true) {
                        clearInterval(interval);
                        setCheckStatus(false);
                        dispatch(setAlert(res.data.message, 'success'));
                        dispatch(fetchSocialAccounts(auth.token));
                        myWindow.close();
                    } else {
                        setCheckStatus(false);
                    }

                }).catch(error => {
                    setCheckStatus(false);
                    window.close();
                })
                console.log("waiting for the next call.");
            }

        }, 5000);


    }


    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        }else{
            console.log(response);
        }
    }

    const connectYoutube = (code) => {
        axios({
            method: "POST",
            url: `${baseURL}save-google-drive`,
            data: JSON.stringify({code: code,user_id: auth.user.id}),
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err=>{

        })
    }


    const fetchAmazon = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-amazon`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setAmazonData(res.data.data);
            } else {
                setAmazonData(false);
            }
        }).catch(err => {

        })
    }


    const InputChange = (e) => {
        /*
                console.log(e.target.value);
        */
        setOptinName(e.target.value);


    }

    const optinInputChange = (e) => {
        setOptinCode(e.target.value);
        myFunction(e.target.value);

    }



    function myFunction(value) {

        let optin_code = document.querySelector("#optin_code_input").value;

        let parser = new DOMParser();
        let html = parser.parseFromString(optin_code, 'text/html');
        let inputs = html.getElementsByTagName('input');
        let form = html.getElementsByTagName('form');


        if (value === "") {
            console.log('m')

        } else {
            let formUrl = form[0].action;
            setOptinUrl(formUrl);
            console.log(formUrl)

        }


        let index;
        for (index = 0; index < inputs.length; ++index) {

            if (inputs[index].name === 'email') {
                let optin_code_email_attr = 'email';
                let hidden_email = inputs[index].value;
                console.log(hidden_email);
                setOptinEmail(hidden_email);


            } else if (inputs[index].name === 'EMAIL') {
                let optin_code_email_attr = 'EMAIL';
                let hidden_inputemail = inputs[index].value;
                console.log(hidden_inputemail);
                setOptinEmail(hidden_inputemail);


            }



            let first_name;
            let last_name;
            if (inputs[index].name === 'u') {
                let hidden_input1 = inputs[index].name;

/*
                setOptin({...optin, first_name: hidden_input1})
*/
                let hidden_inputvalue1 = inputs[index].value;
                first_name= hidden_inputvalue1;
                console.log(first_name);
                setOptinfName(first_name);

            } else if (inputs[index].name === 'FNAME') {
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
                first_name = hidden_inputvalue1;
                setOptinfName(first_name);

            } else if (inputs[index].name === 'first_name') {
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
                first_name = hidden_inputvalue1;
                setOptinfName(first_name);



            } else if (inputs[index].name === 'field_firstname') {
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
                first_name = hidden_inputvalue1;
                console.log(first_name);
                setOptinfName(first_name);


            }

            if (inputs[index].name === 'f') {
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
                first_name = hidden_inputvalue2;
                console.log(first_name);
                setOptinfName(first_name);


            } else if (inputs[index].name === 'LNAME') {
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
                last_name = hidden_inputvalue2;
                console.log(last_name);
                setOptinlName(last_name);

            } else if (inputs[index].name === 'last_name') {
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
                last_name= hidden_inputvalue2;
                console.log(last_name)
                setOptinlName(last_name);


            } else if (inputs[index].name === 'field_lastname') {
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
                last_name = hidden_inputvalue2;
                console.log(last_name);
                setOptinlName(last_name);

            }

            if (inputs[index].name === 'c') {
                let hidden_input3 = inputs[index].name;
                let hidden_inputvalue3 = inputs[index].value;
                last_name = hidden_inputvalue3;
                console.log(last_name);

            }

            if (inputs[index].name === 'm') {
                let hidden_input4 = inputs[index].name;
                let hidden_inputvalue4 = inputs[index].value;
                console.log(hidden_inputvalue4)
            }

            if (inputs[index].name === 'v') {
                let hidden_input5 = inputs[index].name;
                let hidden_inputvalue5 = inputs[index].value;
                console.log(hidden_inputvalue5)
            }

            if (inputs[index].name === 'act') {
                let hidden_input6 = inputs[index].name;
                let hidden_inputvalue6 = inputs[index].value;
                console.log(hidden_inputvalue6)
            }

            if (inputs[index].name === 'campaign_token') {
                let hidden_input7 = inputs[index].name;
                let hidden_inputvalue7 = inputs[index].value;
                console.log(hidden_inputvalue7)
            }

            if (inputs[index].name === 'start_day') {
                let hidden_input8 = inputs[index].name;
                let hidden_inputvalue8 = inputs[index].value;
                console.log(hidden_inputvalue8)
            }

// campaigndashboard
            if (inputs[index].name === 'publiclistid') {
                let hidden_input9 = inputs[index].name;
                let hidden_inputvalue9 = inputs[index].value;
                console.log(hidden_inputvalue9)

            }
            if (inputs[index].name === 'submit') {
                let hidden_input10 = inputs[index].name;
                let hidden_inputvalue10 = inputs[index].value;
            }

            if (inputs[index].name === 'publicaccountid') {
                let hidden_input11 = inputs[index].name;
                let hidden_inputvalue11 = inputs[index].value;
                console.log(hidden_inputvalue11)

            }
            if (inputs[index].name === 'returnUrl') {
                let hidden_input12 = inputs[index].name;
                let hidden_inputvalue12 = inputs[index].value;
                console.log(hidden_inputvalue12)

            }
            if (inputs[index].name === 'activationReturnUrl') {
                let hidden_input13 = inputs[index].name;
                let hidden_inputvalue13 = inputs[index].value;
                console.log(hidden_inputvalue13)

            }

            if (inputs[index].name === 'alreadyactiveurl') {
                let hidden_input14 = inputs[index].name;
                let hidden_inputvalue14 = inputs[index].value;
                console.log(hidden_inputvalue14)

            }

            if (inputs[index].name === 'activationTemplate') {
                let hidden_input15 = inputs[index].name;
                let hidden_inputvalue15 = inputs[index].value;
                console.log(hidden_inputvalue15)
            }

            if (inputs[index].name === 'source') {
                let hidden_input16 = inputs[index].name;
                let hidden_inputvalue16 = inputs[index].value;
                console.log(hidden_inputvalue16)

            }

            if (inputs[index].name === 'verifyemail') {
                let hidden_input17 = inputs[index].name;
                let hidden_inputvalue17 = inputs[index].value;
                console.log(hidden_inputvalue17)

            }

            if (inputs[index].name === 'captcha') {
                let hidden_input18 = inputs[index].name;
                let hidden_inputvalue18 = inputs[index].value;
                console.log(hidden_inputvalue18)

            }

            if (inputs[index].name === 'notifyEmail') {
                let hidden_input19 = inputs[index].name;
                let hidden_inputvalue19 = inputs[index].value;
                console.log(hidden_inputvalue19)

            }

            if (inputs[index].name === 'consenttracking') {
                let hidden_input20 = inputs[index].name;
                let hidden_inputvalue20 = inputs[index].value;
                console.log(hidden_inputvalue20)

            }

            if (inputs[index].name === 'name') {
                let hidden_input21 = inputs[index].name;
                let hidden_inputvalue21 = inputs[index].value;
                console.log(hidden_inputvalue21)

            }

            if (inputs[index].name === 'list') {
                let hidden_input22 = inputs[index].name;
                let hidden_inputvalue22 = inputs[index].value;
                console.log(hidden_inputvalue22)

            }

            if (inputs[index].name === 'form') {
                let hidden_input23 = inputs[index].name;
                let hidden_inputvalue23 = inputs[index].value;
                console.log(hidden_inputvalue23)

            }

            if (inputs[index].name === 'updated_format') {
                let hidden_input24 = inputs[index].name;
                let hidden_inputvalue24 = inputs[index].value;
                console.log(hidden_inputvalue24)
            }

        }


        let optin_code_email_attr;
        document.querySelector("#optin_code_email_attr").valueOf(optin_code_email_attr);
        console.log(optin_code_email_attr)


        let hidden_input1;
        document.querySelector("#hidden_input1").valueOf(hidden_input1);

        let hidden_inputvalue1;
        document.querySelector("#hidden_inputvalue1").valueOf(hidden_inputvalue1);


        let hidden_input2;
        document.querySelector("#hidden_input2").valueOf(hidden_input2);
        let hidden_inputvalue2;
        document.querySelector("#hidden_inputvalue2").valueOf(hidden_inputvalue2);

        let hidden_input3;
        document.querySelector("#hidden_input3").valueOf(hidden_input3);
        let hidden_inputvalue3;
        document.querySelector("#hidden_inputvalue3").valueOf(hidden_inputvalue3);

        let hidden_input4;
        document.querySelector("#hidden_input4").valueOf(hidden_input4);
        let hidden_inputvalue4;
        document.querySelector("#hidden_inputvalue4").valueOf(hidden_inputvalue4);

        let hidden_input5;
        document.querySelector("#hidden_input5").valueOf(hidden_input5);
        let hidden_inputvalue5;
        document.querySelector("#hidden_inputvalue5").valueOf(hidden_inputvalue5);

        let hidden_input6;
        document.querySelector("#hidden_input6").valueOf(hidden_input6);
        let hidden_inputvalue6;
        document.querySelector("#hidden_inputvalue6").valueOf(hidden_inputvalue6);

        let hidden_input7;
        document.querySelector("#hidden_input7").valueOf(hidden_input7);
        let hidden_inputvalue7;
        document.querySelector("#hidden_inputvalue7").valueOf(hidden_inputvalue7);

        let hidden_input8;
        document.querySelector("#hidden_input8").valueOf(hidden_input8);
        let hidden_inputvalue8;
        document.querySelector("#hidden_inputvalue8").valueOf(hidden_inputvalue8);

        let hidden_input9;
        document.querySelector("#hidden_input9").valueOf(hidden_input9);
        let hidden_inputvalue9;
        document.querySelector("#hidden_inputvalue9").valueOf(hidden_inputvalue9);

        let hidden_input10;
        document.querySelector("#hidden_input10").valueOf(hidden_input10);
        let hidden_inputvalue10;
        document.querySelector("#hidden_inputvalue10").valueOf(hidden_inputvalue10);

        let hidden_input11;
        document.querySelector("#hidden_input11").valueOf(hidden_input11);
        let hidden_inputvalue11;
        document.querySelector("#hidden_inputvalue11").valueOf(hidden_inputvalue11);

        let hidden_input12;
        document.querySelector("#hidden_input12").valueOf(hidden_input12);
        let hidden_inputvalue12;
        document.querySelector("#hidden_inputvalue12").valueOf(hidden_inputvalue12);

        let hidden_input13;
        document.querySelector("#hidden_input13").valueOf(hidden_input13);
        let hidden_inputvalue13;
        document.querySelector("#hidden_inputvalue13").valueOf(hidden_inputvalue13);

        let hidden_input14;
        document.querySelector("#hidden_input14").valueOf(hidden_input14);
        let hidden_inputvalue14;
        document.querySelector("#hidden_inputvalue14").valueOf(hidden_inputvalue14);

        let hidden_input15;
        document.querySelector("#hidden_input15").valueOf(hidden_input15);
        let hidden_inputvalue15;
        document.querySelector("#hidden_inputvalue15").valueOf(hidden_inputvalue15);

        let hidden_input16;
        document.querySelector("#hidden_input16").valueOf(hidden_input16);
        let hidden_inputvalue16;
        document.querySelector("#hidden_inputvalue16").valueOf(hidden_inputvalue16);

        let hidden_input17;
        document.querySelector("#hidden_input17").valueOf(hidden_input17);
        let hidden_inputvalue17;
        document.querySelector("#hidden_inputvalue17").valueOf(hidden_inputvalue17);

        let hidden_input18;
        document.querySelector("#hidden_input18").valueOf(hidden_input18);
        let hidden_inputvalue18;
        document.querySelector("#hidden_inputvalue18").valueOf(hidden_inputvalue18);

        let hidden_input19;
        document.querySelector("#hidden_input19").valueOf(hidden_input19);
        let hidden_inputvalue19;
        document.querySelector("#hidden_inputvalue19").valueOf(hidden_inputvalue19);

        let hidden_input20;
        document.querySelector("#hidden_input20").valueOf(hidden_input20);
        let hidden_inputvalue20;
        document.querySelector("#hidden_inputvalue20").valueOf(hidden_inputvalue20);

        let hidden_input21;
        document.querySelector("#hidden_input21").valueOf(hidden_input21);
        let hidden_inputvalue21;
        document.querySelector("#hidden_inputvalue21").valueOf(hidden_inputvalue21);

        let hidden_input22;
        document.querySelector("#hidden_input22").valueOf(hidden_input22);
        let hidden_inputvalue22;
        document.querySelector("#hidden_inputvalue22").valueOf(hidden_inputvalue22);

        let hidden_input23;
        document.querySelector("#hidden_input23").valueOf(hidden_input23);
        let hidden_inputvalue23;
        document.querySelector("#hidden_inputvalue23").valueOf(hidden_inputvalue23);

        let hidden_input24;
        document.querySelector("#hidden_input24").valueOf(hidden_input24);
        let hidden_inputvalue24;
        document.querySelector("#hidden_inputvalue24").valueOf(hidden_inputvalue24);
        //get form action url
        let getActionElement = html.getElementsByTagName('form');
        for (index = 0; index < getActionElement.length; ++index) {
            let optin_code_action_url = getActionElement[index].action;
        }

        let optin_code_action_url;
        document.querySelector("#optin_code_action_url").valueOf(optin_code_action_url);


    }



    const sendOptinData = (e)=>{
        e.preventDefault();
        setArloader(true)
        axios({
            method: "POST",
            url: `${baseURL}/save-optin`,
            data: {
                campaign: optinName,
                url: optinUrl,
                fields: {
                    email: optinEmail,
                    first_name: optinfName,
                    last_name: optinlName
                }
            },
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res=>{
            if(res.data.status===true){
                dispatch(fetchSocialAccounts(auth.token));
                dispatch(setAlert(res.data.message, 'success'));
                setArloader(false);
                setArModel(false);
            }else{
                dispatch(setAlert(res.data.message, 'danger'))
                setArloader(false);
                setArModel(false);
            }
        })
    }

    useEffect(() => {
        fetchAmazon();
    }, [])


    return (
        <>
            <Alert/>


            <div className="connection-box mt-3" id="gdrive">
                <div className="row">
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                        <div className="connection-img">

                            <img src={appData.gDrive.length > 0 ? gdrive_enable : gdrive_disable} alt="Facebook"/>

                        </div>
                    </div>
                    <div className="col-12 col-md-11">
                        <div className="social-connection">
                            <div className="social-content">
                                <h6>G-Drive</h6>

                                {
                                    appData.gDrive.length > 0 ?
                                        appData.gDrive.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                                    <div className="facebook-content">
                                                        <h6>{item.username} </h6>
                                                        <p className="m-0">Added on {item.created}</p>
                                                    </div>
                                                    <div className="facebook-delete">
                                                        <button
                                                            onClick={(e) => deleteIntegration(item.id, 'social_accounts')}
                                                            type="button"
                                                            className="gradiant-button-pb connect-btn">Disconnect
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div
                                            className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                            <div className="youtube-content">

                                                <p className="m-0">Not Connected</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center  mt-4">
                            <GoogleLogin
                                clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                render={(renderProps) => (
                                    <button onClick={renderProps.onClick} type="button" className="gradiant-button-pb connect-btn">Connect</button>
                                )}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                scope="https://www.googleapis.com/auth/drive.readonly"
                                responseType="code"
                                accessType="offline"
                                prompt="consent"
                                cookiePolicy={"single_host_origin"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="connection-box mt-3" id="aws">
                <div className="row">
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                        <div className="connection-img">
                            {
                                <img src={appData.amazondata.length > 0 ? amazon_enable : amazon_icon}
                                     style={{width: "60px"}} alt="Facebook"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-11">
                        <div className="social-connection">
                            <div className="social-content">
                                <h6>Amazon</h6>
                                {
                                    amazonData !== false ?
                                        <div
                                            className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>{amazonData.s3_bucket}</h6>
                                                <p className="m-0">Added on {amazonData.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button onClick={(e) => deleteIntegration(amazonData.id, 'amazon')}
                                                        type="button"
                                                        className="gradiant-button-pb connect-btn">Disconnect
                                                </button>
                                            </div>
                                        </div>
                                        : <div
                                            className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                            <div className="youtube-content">

                                                <p className="m-0">Not Connected</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center  mt-4">
                            <button
                                onClick={() => setS3Model(true)}
                                className="gradiant-button-pb connect-btn">Connect
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="connection-box mt-3" id="aws">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-12 col-md-1 d-flex justify-content-center">*/}
            {/*            <div className="connection-img">*/}
            {/*                {*/}
            {/*                    <img src={appData.amazondata.length > 0 ? dropdox_enable : dropdox_disable}*/}
            {/*                         style={{width: "60px"}} alt="Facebook"/>*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="col-12 col-md-11">*/}
            {/*            <div className="social-connection">*/}
            {/*                <div className="social-content">*/}
            {/*                    <h6>Dropbox</h6>*/}
            {/*                    {*/}
            {/*                        amazonData !== false ?*/}
            {/*                            <div*/}
            {/*                                className="facebook-connection text-white d-flex justify-content-between  align-items-center">*/}
            {/*                                <div className="facebook-content">*/}
            {/*                                    <h6>{amazonData.s3_bucket}</h6>*/}
            {/*                                    <p className="m-0">Added on {amazonData.created}</p>*/}
            {/*                                </div>*/}
            {/*                                <div className="facebook-delete">*/}
            {/*                                    <button onClick={(e) => deleteIntegration(amazonData.id, 'amazon')}*/}
            {/*                                            type="button"*/}
            {/*                                            className="gradiant-button-pb connect-btn">Disconnect*/}
            {/*                                    </button>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            : <div*/}
            {/*                                className="youbtube-connection text-white d-flex align-items-center  justify-content-between">*/}
            {/*                                <div className="youtube-content">*/}

            {/*                                    <p className="m-0">Not Connected</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="d-flex justify-content-center  mt-4">*/}
            {/*                <button*/}
            {/*                    onClick={() => connectDropbox()}*/}
            {/*                    className="gradiant-button-pb connect-btn">Connect*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="connection-box mt-3" id="thumbreel">
                <div className="row">
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                        <div className="connection-img">
                            {
                                <img src={appData.thumbdata.length > 0 ? thumb_enable : thumb_disable} alt="Facebook"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-11">
                        <div className="social-connection">
                            <div className="social-content">
                                <h6>ThumbReel</h6>

                                {
                                    appData.thumbdata.length > 0 ?
                                        appData.thumbdata.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                                    <div className="facebook-content">
                                                        <h6>{item.name} {item?.email}</h6>
                                                        <p className="m-0">Added on {item.created}</p>
                                                    </div>
                                                    <div className="facebook-delete">
                                                        <button onClick={(e) => deleteIntegration(item.id)}
                                                                type="button"
                                                                className="gradiant-button-pb connect-btn">Disconnect
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div
                                            className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                            <div className="youtube-content">

                                                <p className="m-0">Not Connected</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center  mt-4">
                            <button
                                onClick={() => connectPopUp('https://app.thumbreel.io/fetch-app-thumbnail', "GET_THUMBREEL", "ThumbReel", thumb_enable)}
                                className="gradiant-button-pb connect-btn">Connect
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="connection-box mt-3" id="videoreel">
                <div className="row">
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                        <div className="connection-img">
                            {
                                <img src={appData.videodata.length > 0 ? video_enable : video_disable}
                                     style={{width: "60px"}} alt="Facebook"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-11">
                        <div className="social-connection">
                            <div className="social-content">
                                <h6>VideoReel</h6>
                                {
                                    appData.videodata.length > 0 ?
                                        appData.videodata.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                                    <div className="facebook-content">
                                                        <h6>{item.name} {item?.email}</h6>
                                                        <p className="m-0">Added on {item.created}</p>
                                                    </div>
                                                    <div className="facebook-delete">
                                                        <button onClick={(e) => deleteIntegration(item.id)}
                                                                type="button"
                                                                className="gradiant-button-pb connect-btn">Disconnect
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div
                                            className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                            <div className="youtube-content">

                                                <p className="m-0">Not Connected</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center  mt-4">
                            <button
                                onClick={() => connectPopUp('https://v2server.videoreel.io/fetch-app-thumbnail', "GET_VIDEOREEL", "VideoReel", video_enable)}
                                className="gradiant-button-pb connect-btn">Connect
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="connection-box mt-3" id="storyreel">
                <div className="row">
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                        <div className="connection-img">
                            {
                                <img src={appData.storydata.length > 0 ? story_enable : story_disable}
                                     style={{width: "60px"}} alt="Facebook"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-11" >
                        <div className="social-connection">
                            <div className="social-content">
                                <h6>StoryReel</h6>
                                {
                                    appData.storydata.length > 0 ?
                                        appData.storydata.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     className="facebook-connection text-white d-flex justify-content-between align-items-center">
                                                    <div className="facebook-content">
                                                        <h6>{item.name} {item?.email}</h6>
                                                        <p className="m-0">Added on {item.created}</p>
                                                    </div>
                                                    <div className="facebook-delete">
                                                        <button onClick={(e) => deleteIntegration(item.id)}
                                                                type="button"
                                                                className="gradiant-button-pb connect-btn">Disconnect
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div
                                            className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                            <div className="youtube-content">

                                                <p className="m-0">Not Connected</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center  mt-4">
                            <button
                                onClick={() => connectPopUp('https://v2.storyreel.io/fetch-app-thumbnail', "GET_STORYREEL", "StoryReel", story_enable)}
                                className="gradiant-button-pb connect-btn">Connect
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="connection-box mt-3" id="clipsreel">
                <div className="row">
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                        <div className="connection-img">
                            {
                                <img src={appData.clipsdata.length > 0 ? clips_enable : clips_disable}
                                     style={{width: "60px"}} alt="Facebook"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-11" >
                        <div className="social-connection">
                            <div className="social-content">
                                <h6>ClipsReel</h6>
                                {
                                    appData.clipsdata.length > 0 ?
                                        appData.clipsdata.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                                    <div className="facebook-content">
                                                        <h6>{item.name} {item?.email}</h6>
                                                        <p className="m-0">Added on {item.created}</p>
                                                    </div>
                                                    <div className="facebook-delete">
                                                        <button onClick={(e) => deleteIntegration(item.id)}
                                                                type="button"
                                                                className="gradiant-button-pb connect-btn">Disconnect
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div
                                            className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                            <div className="youtube-content">

                                                <p className="m-0">Not Connected</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center  mt-4">
                            <button
                                onClick={() => connectPopUp('https://backend.clipsreel.io/fetch-app-thumbnail', "GET_CLIPSREEL", "ClipsReel", clips_enable)}
                                className="gradiant-button-pb connect-btn">Connect
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="connection-box mt-3" id="autoresponder">
                <div className="row">
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                        <div className="connection-img">
                            {
                                <img src={appData.responderData.length > 0 ? ar_enable : ar_icon}
                                     style={{width: "60px"}} alt="autoresponder"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-11">
                        <div className="social-connection">
                            <div className="social-content">
                                <h6>AutoResponder</h6>
                                {
                                    appData.responderData.length > 0 ?
                                        appData.responderData.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                                    <div className="facebook-content">
                                                        <h6>{item.campaign}</h6>
                                                        <p className="m-0">Added on {item.created}</p>
                                                    </div>
                                                    <div className="facebook-delete">
                                                        <button onClick={(e) => deleteIntegration(item.id,'optin_code')}
                                                                type="button"
                                                                className="gradiant-button-pb connect-btn">Disconnect
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div
                                            className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                            <div className="youtube-content">

                                                <p className="m-0">Not Connected</p>
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                        <div className="d-flex justify-content-center  mt-4">
                            <button
                                onClick={() => setArModel(true)}
                                className="gradiant-button-pb connect-btn">Connect
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{zIndex: '2001'}} className={`modal ${showModal ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{margin: "auto 0px auto auto"}}>
                                <img src={appIcon} width="56px" alt="AWS" className="mr-2"/>
                                Connect {appname}
                            </h5>
                            <button onClick={(e) => setShowModal(false)} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" method="post" onSubmit={(e) => getAppData(e)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Email Address</small>
                                            <input type="email" className="form-control int_auth" placeholder="Enter Email"
                                                   required name="email"
                                                   onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Password</small>
                                            <input type="password" className="form-control int_auth"
                                                   placeholder="********" required name="password"
                                                   onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="d-flex justify-content-center  mt-4">
                                        <button type="submit" className="gradiant-button-pb connect-btn"
                                                disabled={loader}>
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Connect
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`modal ${s3Model ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{margin: "auto 0px auto auto"}}>
                                <img src={amazon_enable} width="56px" alt="AWS" className="mr-2"/>
                                     Integrate AWS
                            </h5>
                            <button onClick={(e) => setS3Model(false)} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" method="post" onSubmit={(e) => connectAmazon(e)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Access Key</small>
                                            <input type="text" className="form-control int_auth" placeholder="Enter Access Key"
                                                   required name="access_key"
                                                   onChange={(e) => handleChangeS3(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Secret Key</small>
                                            <input type="password" className="form-control int_auth" placeholder="********"
                                                   required name="secret_key"
                                                   onChange={(e) => handleChangeS3(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">S3 Bucket</small>
                                            <input type="text" className="form-control int_auth"
                                                   placeholder="Enter Your S3 Bucket" required name="s3_bucket"
                                                   onChange={(e) => handleChangeS3(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small className="form-text text-muted">S3 Region</small>
                                            <input type="text" className="form-control int_auth"
                                                   placeholder="Enter Your S3 Region" required name="s3_region"
                                                   onChange={(e) => handleChangeS3(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="d-flex justify-content-center  mt-4">
                                        <button type="submit" className="gradiant-button-pb connect-btn"
                                                disabled={s3loader}>
                                            {s3loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Connect
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`modal ${arModel ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{margin: "auto 0px auto auto"}}>
                                <img src={ar_enable} width="56px" alt="autoResponder"
                                     className="mr-2"/>
                                     Integrate Auto Responder
                            </h5>
                            <button onClick={(e) => setArModel(false)} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" method="post" onSubmit={(e) => sendOptinData(e)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Campaign Name</small>
                                            <input type="text" className="form-control"
                                                   placeholder="Enter Campaign Name" required name="campaign_name"
                                                   onChange={(e) => InputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <small className="form-text text-muted">Optin Code</small>
                                            {/*<textarea  className="form-control" placeholder="optin code" required name="optin_code"
                                                   onChange={(e) => optinInputChange(e)}
                                            />*/}
                                            <textarea rows="8" placeholder='paste opt in code' id='optin_code_input'
                                                      className="form-control" required name="optionCode"
                                                      onChange={(e) => optinInputChange(e)}/>
                                            <input type="hidden" name="optin_code_email_attr"
                                                   id="optin_code_email_attr"/>
                                            <input type="hidden" name="optin_code_action_url"
                                                   id="optin_code_action_url"/>
                                            <input type="hidden" name="hidden_input1" id="hidden_input1"/>
                                            <input type="hidden" name="hidden_inputvalue1" id="hidden_inputvalue1"/>
                                            <input type="hidden" name="hidden_input2" id="hidden_input2"/>
                                            <input type="hidden" name="hidden_inputvalue2" id="hidden_inputvalue2"/>
                                            <input type="hidden" name="hidden_input3" id="hidden_input3"/>
                                            <input type="hidden" name="hidden_inputvalue3" id="hidden_inputvalue3"/>
                                            <input type="hidden" name="hidden_input4" id="hidden_input4"/>
                                            <input type="hidden" name="hidden_inputvalue4" id="hidden_inputvalue4"/>
                                            <input type="hidden" name="hidden_input5" id="hidden_input5"/>
                                            <input type="hidden" name="hidden_inputvalue5" id="hidden_inputvalue5"/>
                                            <input type="hidden" name="hidden_input6" id="hidden_input6"/>
                                            <input type="hidden" name="hidden_inputvalue6" id="hidden_inputvalue6"/>
                                            <input type="hidden" name="hidden_input7" id="hidden_input7"/>
                                            <input type="hidden" name="hidden_inputvalue7" id="hidden_inputvalue7"/>
                                            <input type="hidden" name="hidden_input8" id="hidden_input8"/>
                                            <input type="hidden" name="hidden_inputvalue8" id="hidden_inputvalue8"/>
                                            <input type="hidden" name="hidden_input9" id="hidden_input9"/>
                                            <input type="hidden" name="hidden_inputvalue9" id="hidden_inputvalue9"/>
                                            <input type="hidden" name="hidden_input10" id="hidden_input10"/>
                                            <input type="hidden" name="hidden_inputvalue10" id="hidden_inputvalue10"/>
                                            <input type="hidden" name="hidden_input11" id="hidden_input11"/>
                                            <input type="hidden" name="hidden_inputvalue11" id="hidden_inputvalue11"/>
                                            <input type="hidden" name="hidden_input12" id="hidden_input12"/>
                                            <input type="hidden" name="hidden_inputvalue12" id="hidden_inputvalue12"/>
                                            <input type="hidden" name="hidden_input13" id="hidden_input13"/>
                                            <input type="hidden" name="hidden_inputvalue13" id="hidden_inputvalue13"/>
                                            <input type="hidden" name="hidden_input14" id="hidden_input14"/>
                                            <input type="hidden" name="hidden_inputvalue14" id="hidden_inputvalue14"/>
                                            <input type="hidden" name="hidden_input15" id="hidden_input15"/>
                                            <input type="hidden" name="hidden_inputvalue15" id="hidden_inputvalue15"/>
                                            <input type="hidden" name="hidden_input16" id="hidden_input16"/>
                                            <input type="hidden" name="hidden_inputvalue16" id="hidden_inputvalue16"/>
                                            <input type="hidden" name="hidden_input17" id="hidden_input17"/>
                                            <input type="hidden" name="hidden_inputvalue17" id="hidden_inputvalue17"/>
                                            <input type="hidden" name="hidden_input18" id="hidden_input18"/>
                                            <input type="hidden" name="hidden_inputvalue18" id="hidden_inputvalue18"/>
                                            <input type="hidden" name="hidden_input19" id="hidden_input19"/>
                                            <input type="hidden" name="hidden_inputvalue19" id="hidden_inputvalue19"/>
                                            <input type="hidden" name="hidden_input20" id="hidden_input20"/>
                                            <input type="hidden" name="hidden_inputvalue20" id="hidden_inputvalue20"/>
                                            <input type="hidden" name="hidden_input21" id="hidden_input21"/>
                                            <input type="hidden" name="hidden_inputvalue21" id="hidden_inputvalue21"/>
                                            <input type="hidden" name="hidden_input22" id="hidden_input22"/>
                                            <input type="hidden" name="hidden_inputvalue22" id="hidden_inputvalue22"/>
                                            <input type="hidden" name="hidden_input23" id="hidden_input23"/>
                                            <input type="hidden" name="hidden_inputvalue23" id="hidden_inputvalue23"/>
                                            <input type="hidden" name="hidden_input24" id="hidden_input24"/>
                                            <input type="hidden" name="hidden_inputvalue24" id="hidden_inputvalue24"/>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="d-flex justify-content-center  mt-4">
                                        <button type="submit" className="gradiant-button-pb connect-btn"
                                                disabled={arloader}>
                                            {arloader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Connect
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to disconnect this account?
            </SweetAlert>

        </>

    )
}

export default ConnectReelApps;
