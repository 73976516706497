import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useSelector } from "react-redux";

const ManageAccount = (props) => {
  const auth = useSelector((state) => state.auth);
  const [toggle, setToggle] = useState(false);
  const [button, setButton] = useState("Delete");
  const [showDelete, setShowDelete] = useState(false);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState(null);
  const [id, setId] = useState({
    client_id: null,
  });
  const [editAccount, setEditAccount] = useState({
    name: "",
    password: "",
    id: "",
  });

  const handleDelete = (id) => {
    setShowDelete(true);
    setId({ client_id: id });
  };

  const onConfirm = () => {
    deleteClientTeam();
  };

  const onCancel = () => {
    setShowDelete(false);
  };

  const deleteClientTeam = () => {
    setButton("Deleting...");
    axios({
      method: "POST",
      url: `${baseURL}delete-client`,
      data: id,
      headers: {
        "Content-Type": "application/json",
        Authorization: props.auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          props.fetchAccountData();
        }
        setButton("Delete");
        setShowDelete(false);
      })
      .catch((err) => {
        setButton("Delete");
        setShowDelete(false);
      });
  };

  const editTheAccount = (e) => {
    setEditAccount({ ...editAccount, [e.target.name]: e.target.value });
  };

  const updateAccount = () => {
    setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
    };

    axios.post(`${baseURL}update-client`, editAccount, config).then((res) => {
      if (res.data.status === true) {
        setLoader(false);
        props.fetchAccountData();
      }
    });
  };

  return (
    <>
     {/* <h4 className="text-left">{props.type} Account</h4>
      <table className="table table-striped table-dark">
        <thead>
          <tr>
            <th scope="col">S No.</th>
            <th scope="col">Name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Created</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.users.length > 0
            ? props.users.map((user, index) => {
                return (
                  <tr key={user.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.created}</td>
                    <td>
                      <button onClick={() => handleDelete(user.id)}>
                        <i className="far fa-trash-alt" />
                      </button>
                      <button
                        onClick={() => {
                          setUser(user);
                          setToggle(!toggle);
                          setEditAccount({ ...editAccount, id: user.id });
                        }}
                      >
                        <i className="far fa-edit" />
                      </button>
                    </td>
                  </tr>
                );
              })
            : ""}
          <tr
            style={
              toggle === false ? { display: "none" } : { display: "contents" }
            }
          >
            <td className="d-none" />
            <td colSpan={12}>
              <div style={{ width: "100%" }}>
                <div className="col-12">
                  <p className="text-white"></p>
                  <div className="mt-2 mb-0">
                    <h6
                      className="text-white text-left mb-4 mt-3"
                      style={{
                        fontSize: "18px !important",
                        marginLeft: "-10px",
                        textAlign: "left !important",
                      }}
                    >
                      {user !== null && user.is_virtual_account === "1"
                        ? " Edit Team Member"
                        : user !== null && user.is_client_account === "1"
                        ? " Edit Client Member"
                        : ""}
                    </h6>
                    <div>
                      <form>
                        <div className="row d-flex justify-content-center ">
                          <div className="col col-lg-6 col-12 p-1">
                            <div className="form-group form-group-mb">
                              <label className="input-label-pos">Name</label>
                              <input
                                className="form-control input-dark-accnt personal-info  w-100"
                                type="text"
                                name="name"
                                placeholder="Name"
                                required
                                // value={clientsInfo.firstName}
                                onChange={(e) => editTheAccount(e)}
                              />
                            </div>
                          </div>

                          <div className="col col-lg-6 col-12 p-1">
                            <div className="form-group form-group-mb">
                              <label className="input-label-pos">
                                Email Address{" "}
                              </label>
                              <input
                                className="form-control input-dark-accnt personal-info"
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                required
                                value={user !== null ? user.email : ""}
                                // onChange={(e) => editClientInfo(e)}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col col-lg-6 col-12 p-1">
                            <div className="form-group form-group-mb">
                              <label className="input-label-pos">
                                Password
                              </label>
                              <input
                                className="form-control input-dark-accnt personal-info"
                                type="password"
                                name="password"
                                placeholder="Password   (leave blank for same password)"
                                onChange={(e) => editTheAccount(e)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col col-lg-6 col-12 p-1">
                            <div className="form-group form-group-mb">
                              <label className="input-label-pos">
                                Account Type{" "}
                              </label>
                              <input
                                className="form-control input-dark-accnt personal-info"
                                type="text"
                                name="Account Type"
                                placeholder="Account Type"
                                required
                                value={
                                  user !== null &&
                                  user.is_virtual_account === "1"
                                    ? "Team"
                                    : user !== null &&
                                      user.is_client_account === "1"
                                    ? "Client"
                                    : "Reseller"
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col col-md-12 text-center mt-5 mb-5">
                      <button
                        className="btn btn-3 accnt-mngmt-btn mr-xl-2 mb-2"
                        title="Edit"
                        data-toggle="collapse"
                        data-parent="#accordion1"
                        // href={`#${client.id}client`}
                        onClick={() => setToggle(false)}
                      >
                        Cancel
                      </button>
                      {loader === true ? (
                        <button
                          className="btn btn-3 accnt-mngmt-btn mb-2"
                          type="button"
                          disabled
                          style={{
                            background: "#000",
                            font: "16px",
                            borderColor: "#000",
                            color: "white",
                          }}
                        >
                          <span
                            className="spinner-border spinner-border-sm text-light mx-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <strong>Updating...</strong>
                        </button>
                      ) : (
                        <button
                          onClick={() => updateAccount()}
                          className="btn btn-3 accnt-mngmt-btn mb-2"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <SweetAlert
        warning
        showCancel
        confirmBtnText={button}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Are you sure?"
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        focusCancelBtn
        show={showDelete}
      >
        Do you want to delete this {props.type}
      </SweetAlert>*/}

      <div className="row mt-5">
        <div className="col-12 mx-auto">
          <div className="manage-data-sec">
            <h5 className="mb-5 text-left">Manage Client Accounts</h5>
          </div>
        </div>
        <div className="col-12 mx-auto">
          <div className="manage-data-box">
            <div className="manage-data-box-content-table">
              <table className="table table-dark">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Account Type</th>
                  <th scope="col">Created</th>
                  <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {/*<tr>
                  <th scope="row">Queenie Lodonia</th>
                  <td>queenlyexxpert@gmail</td>
                  <td>Team Member</td>
                  <td>2021-11-12 03:11:51</td>
                  <td className="action-btn">
                    <a href="#" className="edit-icon">
                      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </a>
                    <a href="#" className="delete-icon">
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Queenie Lodonia</th>
                  <td>queenlyexxpert@gmail</td>
                  <td>Team Member</td>
                  <td>2021-11-12 03:11:51</td>
                  <td className="action-btn">
                    <a href="#" className="edit-icon">
                      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </a>
                    <a href="#" className="delete-icon">
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Queenie Lodonia</th>
                  <td>queenlyexxpert@gmail</td>
                  <td>Team Member</td>
                  <td>2021-11-12 03:11:51</td>
                  <td className="action-btn">
                    <a href="#" className="edit-icon">
                      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </a>
                    <a href="#" className="delete-icon">
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>*/}

                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
      <SweetAlert
          warning
          showCancel
          confirmBtnText={button}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          title="Are you sure?"
          onConfirm={() => onConfirm()}
          onCancel={() => onCancel()}
          focusCancelBtn
          show={showDelete}
      >
        Do you want to delete this {props.type}
      </SweetAlert>
    </>
  );
};

export default React.memo(ManageAccount);
