import React, {useEffect} from "react";


import ConnectReelApps from "./ConnectReelApps";
import {useDispatch, useSelector} from "react-redux";
import {fetchSocialAccounts} from "../../actions/socialAction";
import Navbar from "../Navbar";
import Footer from "../Footer";
import IntegrationHead from "./IntegrationHead";
import Webinar from "../dashboard/Webinar";
import {Helmet} from "react-helmet";

const Integration = () => {

    const dispatch = useDispatch();

    const socialData = useSelector(state => state.social);
    const brandName = useSelector(state => state.rebrand.data);

    useEffect(() => {
        dispatch(fetchSocialAccounts());
    }, [])


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Integration</title>
            </Helmet>
            <div id="integrations-page" className="inner-page">
                <Navbar/>
                <section className="integration-sec">
                    <div className="container">
                        <IntegrationHead/>
                    </div>
                </section>
                <section className="my-connection">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-white">My Connection</h4>
                                <ConnectReelApps/>

                            </div>
                        </div>
                    </div>
                </section>
                <Webinar/>
                <Footer/>
            </div>
        </>
    )
}

export default Integration;
