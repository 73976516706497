import Logo from "../../images/playerReel-logo.png";
import React from "react";

const Header = ()=>{

    return(
    <>
        <header>
            <nav id="navbar_top" className="navbar navbar-expand-lg navbar-light nav-transparent">
                <div className="container">

                        <img src={Logo} alt="PlayerReel Logo" className="comp-logo"/>

                </div>
            </nav>
        </header>
        </>

    )
}

export default Header;
