import React, {useEffect} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardAbout from "./DashboardAbout";
import DashboardContent from "./DashboardContent";
import Webinar from "./Webinar";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {removeAlert} from "../../actions/alert";
const Dashboard  = () => {
    const dispatch = useDispatch();
    const brandName = useSelector(state => state.rebrand.data);

    useEffect(()=>{
        dispatch(removeAlert());
    },[])

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Dashboard </title>
            </Helmet>
            <Navbar/>
            <DashboardHead/>
            <DashboardContent/>
             <DashboardAbout/>
             <Webinar/>
            <Footer/>
         </>
    )
}

export default Dashboard;
