import React, {useState} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import YoutubeCard from "./YoutubeCard";


const VideoFinder = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [keyword, setKeyword] = useState({
        keyword: '',
        license: false,
        next_page_token: ''
    });
    const [loader, setLoader] = useState(false);
    const [loadMore, setLoadMore] = useState(false);
    const [youtubeData, setYoutubeData] = useState([]);
    const [q, setQ] = useState('');

    const handleInput = (e) => {
        setKeyword({...keyword, [e.target.name] : e.target.value });

        setYoutubeData([]);
    }

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (articles) => {
        return articles.filter(
            article => article.channel_title.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }

    const handleSearch = (e ,type) => {
        e.preventDefault();

        if(type==="more"){
            setLoadMore(true);
        }else {
            setLoader(true);
        }

        axios({
            method: "POST",
            url: `${baseURL}search-youtube`,
            data: keyword,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res=>{
            if(res.data.status === true){
                setYoutubeData(youtubeData.concat(res.data.data));
                setKeyword({...keyword, next_page_token: res.data.next_page_token});
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }

            setLoader(false);
            setLoadMore(false);
        }).catch(err=>{
            dispatch(setAlert('Something went wrong, Please try again','danger'))
            setLoader(false);
            setLoadMore(false);
        })
    }

    const handleSort = (e) => {
        let sortData;

        if(e.target.value === "like")
            sortData = [...youtubeData].sort((a, b) => b.stats.like - a.stats.like)
        else if(e.target.value === "dislike")
            sortData = [...youtubeData].sort((a, b) => b.stats.dislike - a.stats.dislike)
        else if(e.target.value === "comments")
            sortData = [...youtubeData].sort((a, b) => b.stats.comments - a.stats.comments)
        else
            sortData = [...youtubeData].sort((a, b) => b.stats.views - a.stats.views)

        setYoutubeData(sortData);

    }

    return(
        <>
            <Navbar/>
            <Alert/>
            <div id="videoFinder" className="videofinder">
                <div className="container">
                    <div>
                        <form method="post" onSubmit={(e)=>handleSearch(e,'search')} className="row d-flex justify-content-center mt-4">
                            <div className="col-md-12 col-12">
                            <h3><span className="orange">YouTube Video</span> Finder</h3>
                                <div className="content-box content-box-bg-black search-form content-bor20">
                                <div className="form-row row justify-content-around">
                                    <div className="form-group col-md-6">
                                        <label>Keyword</label>
                                        <div className="input-group">
                                            <span className="input-group-text white">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input style={{color: "#000"}} type="text" onChange={(e)=>handleInput(e)}
                                                   className="select-custom search-input form-control" name="keyword" aria-label="Search Keyword"
                                                   placeholder="Search Keyword"/>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Add Filter</label>
                                        <div className="input-group">
                                            
                                            <select onChange={(e)=>handleInput(e)} className="form-control add-filter select-custom">
                                                <option selected value={false}>All Videos</option>
                                                <option value={true}>Creative Common License</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        {/* <label className="">Search</label> */}
                                        <button disabled={loader} type="submit" className="btn btn-bundle primary-gradient search">
                                            {loader ? <i className="fa fa-spinner fa-spin mr-1"/> : ''} Search
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </form>
                        <div className="content-bor20 content-box-bg-black  my-4">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-12 col-12">
                                    <div className="content-box content-box-bg-black search-form-keyword">
                                        <div className="row ">
                                            <div className="col-12 col-md-5 d-flex align-items-center">
                                                {
                                                    keyword.keyword !== "" ?
                                                        <p>{youtubeData.length} Videos found for "{keyword.keyword}"</p>
                                                    : ''
                                                }
                                            </div>
                                            <div className="col-12 col-md-7">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        {/* <label for="Search">Search</label> */}
                                                        <div className="input-group ">
                                                            <span className="input-group-text black">
                                                                <i className="fas fa-search"></i>
                                                            </span>
                                                            <input type="text" className="form-control input-bg-black" id="Search"
                                                                   onChange={(e)=>onSearch(e)} placeholder="Search Video"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        {/* <label for="addfilter">Add Filter</label> */}
                                                        <div className="input-group">
                                                            <select name="filter" onChange={(e)=>handleSort(e)} className="select-custom form-control sort-by ">
                                                                <option selected disabled>Sort By</option>
                                                                <option value="like">Likes</option>
                                                                <option value="views">Views</option>
                                                                <option value="comments">Comments</option>
                                                                <option value="dislike">Dislikes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <div className="list-view d-flex align-items-center">
                                                            <p>List View</p>
                                                            <i class="fas fa-list-ul"></i>
                                                            <i class="fas fa-th-large"></i>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-md-3">*/}
                                                    {/*    <div className="list-view d-flex align-items-center justify-content-center">*/}
                                                    {/*        <p>List View</p>*/}
                                                    {/*        <a href="#" className="btn btn-grid" role="button"><i className="fas fa-list-ul"></i></a>*/}
                                                    {/*        <a href="#" className="btn btn-grid" role="button"><i className="fas fa-th-large"></i></a>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box-bg-black">
                                    <div className="table-box">
                                            <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                <th scope="col">Video Description</th>
                                                <th scope="col">Views</th>
                                                <th scope="col">Likes</th>
                                                <th scope="col">Comments</th>
                                                <th scope="col">Dislikes</th>
                                                <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                youtubeData.length > 0 ?
                                                    search(youtubeData).map((item,index)=>(
                                                        <YoutubeCard item={item} index={index}/>
                                                    ))
                                                : ''
                                            }
                                            </tbody>
                                            </table>
                                        {
                                            youtubeData.length > 0 ?
                                                <div className="text-center">
                                                    <button disabled={loadMore} onClick={(e)=>handleSearch(e,'more')}
                                                            className="btn btn-gradient-orange">
                                                        {loadMore ? <i className="fa fa-spinner fa-spin mr-1"/> : ''} Load More
                                                    </button>
                                                </div>
                                            : ''
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}


export default VideoFinder;