import React, {useState} from "react";
import placeholderImage from "../../images/placeholder-image.png";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

const YoutubeCard = ({item,index}) => {

    const [copyUrl, setCopyUrl] = useState(false)

    return(
        <>
            <tr className="table-finder" key={index}>
                <td scope="td">
                    <div className="table-video-box d-flex">
                        <div className="table-video-box-img">
                            <img src={item?.thumbnail!==""?item.thumbnail:placeholderImage} className="video-img" alt="video img" />
                        </div>
                        <div className="table-video-box-text">
                            <h6>{item?.channel_title}</h6>
                            <p>{item.description}</p>
                            <div className="table-video-box-text-buttons">
                                {
                                    item.tags !== null ?
                                        item.tags.slice(0, 6).map((tag,index)=>(
                                            <button key={index} type="button" className="btn btn-light">{tag}</button>
                                        ))
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </td>
                <td>{item.stats.views}</td>
                <td>{item.stats.like===null?'N/A':item.stats.like}</td>
                <td>{item?.stats.comments===null?'N/A':item?.stats.comments}</td>
                <td>{item?.stats.dislike===null?'N/A':item?.stats.dislike}</td>
                <td>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fas fa-ellipsis-h"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                {/*<a className="dropdown-item cursor-pointer">Copy URL</a>*/}
                                <CopyToClipboard text={`https://www.youtube.com/watch?v=${item.id}`} onCopy={()=>setCopyUrl(true)}>
                                    <a className="dropdown-item cursor-pointer">
                                        {copyUrl ? 'Copied' : 'Copy URL'}
                                    </a>
                                </CopyToClipboard>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            
        </>
    )
}


export default React.memo(YoutubeCard);