import join_now from "../../images/join-now-img.png";
import play_icon from "../../images/play-icon.png";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const Webinar = ()=>{

    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])
    return(
        <>
            <section id="join-now">
                <div className="container">
                    <div className="row revert">
                        <div className="col-12 col-md-6 f-right">
                            <div className="join-now-img">
                                <img src={join_now} alt="join now"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 f-left">
                            <div className="pt-sm-3">
                                <h5 className="subtitle text-white">{ rebrand.data === false ? <span>PlayerReel</span> : <span>{rebrand.data.name}</span>
                                } LESSON #1</h5>
                                <h2 className="text-medium"><span className="highlight red">Join Us</span> for our LIVE
                                    Webinar.</h2>
                                <p className="mt-4">Join us for a private training that will walk you through how to use
                                    { rebrand.data === false ? <span> PlayerReel</span> : <span> {rebrand.data.name}</span>
                                    } to help you create and sell videos for maximum engagement, views and
                                    profits. This training or upgrade will not be made available afterwards. Sign up and
                                    make sure to show up on this live training with all your questions.</p>

                                {/*<a href="https://abhineos.com/training" target="_blank">*/}
                                {/*    <button type="button" className="gradiant-button-pb watch-btn mt-4">*/}
                                {/*        Sign Up Now*/}
                                {/*    </button>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
    )
}

export default Webinar;
