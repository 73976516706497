import axios from "axios";
import {baseURL} from "../global/global";
import {setAlert} from "./alert";
import integrateReducer from "../reducers/integrateReducer";
import {saveReelAppsIntegration} from "./integrateAction";

export const addSocialAccounts = (data) => (dispatch) => {
    dispatch({type: 'ADD_SOCIAL_ACCOUNTS', payload: data})
}

export const fetchSocialAccounts = (token) => (dispatch,getState) => {
    console.log('fetch')
    axios({
        method: "POST",
        url: `${baseURL}fetch-social-accounts`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch(saveReelAppsIntegration(res.data.data));
            // dispatch(addSocialAccounts(res.data.data));
         }
        dispatch(integrateReducer(res.data))
    }).catch(err=>{
    })
}


export const saveFacebook = (data) => async (dispatch, getState) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    };
    const body = JSON.stringify(data);

    try{
        const res = await axios.post(`${baseURL}save-facebook`,body,config);
        console.log(res);

        if(res.data.status === true){
            dispatch(setAlert(res.data.message,"success"));
            dispatch(fetchSocialAccounts(getState().auth.token));
        }else{
            dispatch(setAlert(res.data.message,"danger"));
        }
    }catch(err){
        console.log(err);
    }
}
