import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";

const AddAccount = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [resellerData, setResellerData] = useState([]);

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team_member',
        email: '',
        name: '',
        password: ''
    });

    const onInputChange = (e) => {
        setUserDetails({...userDetails, [e.target.name] : e.target.value});

    }

    const fetchClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setTeamData(res.data.data.team_members);
                setClientData(res.data.data.clients);
                setResellerData(res.data.data.resellers);
            }
        }).catch(error=>{

        })
    }

    const addClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}add-client`,
            data: userDetails,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'));
                fetchClientTeam()
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setLoader(false);
        }).catch(error=>{
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        addClientTeam();
    }

    useEffect(()=>{
        fetchClientTeam();
    },[]);

    return(
        <>
            <Alert/>
            {/*<div className="container">
                <form className="row text-left" method="post" onSubmit={(e)=>handleFormSubmit(e)}>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Account Type</label>
                            <select className="form-control" name="type" onChange={(e)=>handleInputChange(e)}>
                                <option value="team_member">Team Member</option>
                                <option value="client">Client</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Email address</label>
                            <input type="email" className="form-control" placeholder="Enter email" name="email" onChange={(e)=>handleInputChange(e)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label >Name</label>
                            <input type="text" className="form-control" placeholder="Enter name" name="name" onChange={(e)=>handleInputChange(e)} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" placeholder="Password" name="password" onChange={(e)=>handleInputChange(e)} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">Create</button>
                    </div>
                </form>

                <div className="mt-5"></div>
                <ManageAccount type="Client" users={clientData} auth={auth} fetchAccountData={fetchAccountData} />
                <ManageAccount type="Team Member" users={teamData} auth={auth} fetchAccountData={fetchAccountData} />
            </div>*/}
                    <div className="row">
                    <div className="col-12 mx-auto">
                            <div className="personal-details-box">
                                <h5 className="mb-5 text-left">Create Accounts</h5>
                                <form className="personal-details-box-form" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-3">
                                                <small className="form-text text-muted">Account Type</small>

                                                <select className="form-control" id="InputAccount" name="type" onChange={(e)=>onInputChange(e)}>
                                                    <option style={{backgroundColor:"rgb(39 31 31)"}} value="team_member">Team Member</option>
                                                    <option style={{backgroundColor:"rgb(39 31 31)"}} value="client">Client Account</option>
                                                    <option style={{backgroundColor:"rgb(39 31 31)"}} value="reseller">Reseller Account</option>
                                                </select>
                                             </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-3">
                                                <small className="form-text text-muted">Account Name</small>
                                                <input type="text" className="form-control" id="InputName"
                                                       placeholder="Enter Name" name="name" onChange={(e)=>onInputChange(e)}/>
                                             </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-3">
                                                <small className="form-text text-muted">Account Email </small>
                                                <input type="email" className="form-control" id="InputEmail"
                                                       placeholder="Email Address"  name="email" onChange={(e)=>onInputChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-3">
                                                <small className="form-text text-muted">Account Password </small>
                                                <input type="password" className="form-control" id="InputPassword"
                                                       placeholder="***********" name="password" onChange={(e)=>onInputChange(e)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn gradiant-button-pb btn-submit mt-4" disabled={loader}>
                                        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Create
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

            <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} />
            <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} type="Team Member" />
            <ManageTeam users={resellerData} fetchClientTeam={fetchClientTeam} type="Reseller" />
        </>
    )
}

export default AddAccount;
