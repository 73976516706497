import Navbar from "./Navbar";
import { useState} from "react";

const  AutoResponder = ()=>{
    const [optin, setOptin] = useState('')


    const handleInputChange = (e)=>{
/*
        console.log(e.target.value);
*/
        setOptin(e.target.value);
        myFunction()
     }






    function myFunction()
    {
        let optin_code = document.querySelector("#optin_code_input").value;
        let parser = new DOMParser();
        let html = parser.parseFromString(optin_code, 'text/html');
        let inputs = html.getElementsByTagName('input');
        console.log(html);
        console.log(inputs)
        let index;
        for (index = 0; index < inputs.length; ++index)
        {
            if(inputs[index].name === 'email')
            {
                let optin_code_email_attr = 'email';
            }else if(inputs[index].name === 'EMAIL'){
                let optin_code_email_attr = 'EMAIL';
            }

            if(inputs[index].name === 'u')
            {
                let hidden_input1 = inputs[index].name;
                console.log(hidden_input1)
                let hidden_inputvalue1 = inputs[index].value;
                console.log(hidden_inputvalue1)

            }else if(inputs[index].name === 'FNAME'){
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
               /* console.log(hidden_inputvalue1);*/
            }else if(inputs[index].name === 'first_name'){
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
/*
                console.log(hidden_inputvalue1)
*/
            }else if(inputs[index].name === 'field_firstname'){
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
                console.log(hidden_inputvalue1);

            }

            if(inputs[index].name === 'f')
            {
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
                console.log(hidden_inputvalue2);

            }else if(inputs[index].name === 'LNAME'){
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
/*
                console.log(hidden_inputvalue2);
*/

            }else if(inputs[index].name === 'last_name'){
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;

            }else if(inputs[index].name === 'field_lastname'){
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
/*
                console.log(hidden_inputvalue2);
*/

            }

            if(inputs[index].name === 'c')
            {
                let hidden_input3 = inputs[index].name;
                let hidden_inputvalue3 = inputs[index].value;
            }

            if(inputs[index].name === 'm')
            {
                let hidden_input4 = inputs[index].name;
                let hidden_inputvalue4 = inputs[index].value;
            }

            if(inputs[index].name === 'v')
            {
                let hidden_input5 = inputs[index].name;
                let  hidden_inputvalue5 = inputs[index].value;
            }

            if(inputs[index].name === 'act')
            {
                let hidden_input6 = inputs[index].name;
                let hidden_inputvalue6 = inputs[index].value;
            }

            if(inputs[index].name === 'campaign_token')
            {
                let hidden_input7 = inputs[index].name;
                let hidden_inputvalue7 = inputs[index].value;
            }

            if(inputs[index].name === 'start_day')
            {
                let hidden_input8 = inputs[index].name;
                let hidden_inputvalue8 = inputs[index].value;
            }

// campaigndashboard
            if(inputs[index].name === 'publiclistid')
            {
                let hidden_input9 = inputs[index].name;
                let hidden_inputvalue9 = inputs[index].value;
            }
            if(inputs[index].name === 'submit')
            {
                let hidden_input10 = inputs[index].name;
                let hidden_inputvalue10 = inputs[index].value;
            }

            if(inputs[index].name === 'publicaccountid')
            {
                let hidden_input11 = inputs[index].name;
                let hidden_inputvalue11 = inputs[index].value;
            }
            if(inputs[index].name === 'returnUrl')
            {
                let hidden_input12 = inputs[index].name;
                let hidden_inputvalue12 = inputs[index].value;
            }
            if(inputs[index].name === 'activationReturnUrl')
            {
                let hidden_input13 = inputs[index].name;
                let hidden_inputvalue13 = inputs[index].value;
            }

            if(inputs[index].name === 'alreadyactiveurl')
            {
                let hidden_input14 = inputs[index].name;
                let hidden_inputvalue14 = inputs[index].value;
            }

            if(inputs[index].name === 'activationTemplate')
            {
                let hidden_input15 = inputs[index].name;
                let hidden_inputvalue15 = inputs[index].value;
            }

            if(inputs[index].name === 'source')
            {
                let hidden_input16 = inputs[index].name;
                let hidden_inputvalue16 = inputs[index].value;
            }

            if(inputs[index].name === 'verifyemail')
            {
                let hidden_input17 = inputs[index].name;
                let hidden_inputvalue17 = inputs[index].value;
            }

            if(inputs[index].name === 'captcha')
            {
                let hidden_input18 = inputs[index].name;
                let hidden_inputvalue18 = inputs[index].value;
            }

            if(inputs[index].name === 'notifyEmail')
            {
                let hidden_input19 = inputs[index].name;
                let hidden_inputvalue19 = inputs[index].value;
            }

            if(inputs[index].name === 'consenttracking')
            {
                let hidden_input20 = inputs[index].name;
                let hidden_inputvalue20 = inputs[index].value;
            }

            if(inputs[index].name === 'name')
            {
                let hidden_input21 = inputs[index].name;
                let hidden_inputvalue21 = inputs[index].value;
            }

            if(inputs[index].name === 'list')
            {
                let hidden_input22 = inputs[index].name;
                let hidden_inputvalue22 = inputs[index].value;
            }

            if(inputs[index].name === 'form')
            {
                let hidden_input23 = inputs[index].name;
                let hidden_inputvalue23 = inputs[index].value;
            }

            if(inputs[index].name === 'updated_format')
            {
                let hidden_input24 = inputs[index].name;
                let hidden_inputvalue24 = inputs[index].value;
            }

        }



        let optin_code_email_attr;
        document.querySelector("#optin_code_email_attr").valueOf(optin_code_email_attr);
        console.log(optin_code_email_attr)

        let hidden_input1;
        document.querySelector("#hidden_input1").valueOf(hidden_input1);
        console.log(hidden_input1)
        let hidden_inputvalue1;
        document.querySelector("#hidden_inputvalue1").valueOf(hidden_inputvalue1);

        console.log(hidden_inputvalue1);

        let hidden_input2;
        document.querySelector("#hidden_input2").valueOf(hidden_input2);
        let hidden_inputvalue2;
        document.querySelector("#hidden_inputvalue2").valueOf(hidden_inputvalue2);

        let hidden_input3;
        document.querySelector("#hidden_input3").valueOf(hidden_input3);
        let hidden_inputvalue3;
        document.querySelector("#hidden_inputvalue3").valueOf(hidden_inputvalue3);

        let hidden_input4;
        document.querySelector("#hidden_input4").valueOf(hidden_input4);
        let hidden_inputvalue4;
        document.querySelector("#hidden_inputvalue4").valueOf(hidden_inputvalue4);

        let hidden_input5;
        document.querySelector("#hidden_input5").valueOf(hidden_input5);
        let hidden_inputvalue5;
        document.querySelector("#hidden_inputvalue5").valueOf(hidden_inputvalue5);

        let hidden_input6;
        document.querySelector("#hidden_input6").valueOf(hidden_input6);
        let hidden_inputvalue6;
        document.querySelector("#hidden_inputvalue6").valueOf(hidden_inputvalue6);

        let hidden_input7;
        document.querySelector("#hidden_input7").valueOf(hidden_input7);
        let hidden_inputvalue7;
        document.querySelector("#hidden_inputvalue7").valueOf(hidden_inputvalue7);

        let hidden_input8;
        document.querySelector("#hidden_input8").valueOf(hidden_input8);
        let hidden_inputvalue8;
        document.querySelector("#hidden_inputvalue8").valueOf(hidden_inputvalue8);

        let hidden_input9;
        document.querySelector("#hidden_input9").valueOf(hidden_input9);
        let hidden_inputvalue9;
        document.querySelector("#hidden_inputvalue9").valueOf(hidden_inputvalue9);

        let hidden_input10;
        document.querySelector("#hidden_input10").valueOf(hidden_input10);
        let hidden_inputvalue10;
        document.querySelector("#hidden_inputvalue10").valueOf(hidden_inputvalue10);

        let hidden_input11;
        document.querySelector("#hidden_input11").valueOf(hidden_input11);
        let hidden_inputvalue11;
        document.querySelector("#hidden_inputvalue11").valueOf(hidden_inputvalue11);

        let hidden_input12;
        document.querySelector("#hidden_input12").valueOf(hidden_input12);
        let hidden_inputvalue12;
        document.querySelector("#hidden_inputvalue12").valueOf(hidden_inputvalue12);

        let hidden_input13;
        document.querySelector("#hidden_input13").valueOf(hidden_input13);
        let hidden_inputvalue13;
        document.querySelector("#hidden_inputvalue13").valueOf(hidden_inputvalue13);

        let hidden_input14;
        document.querySelector("#hidden_input14").valueOf(hidden_input14);
        let hidden_inputvalue14;
        document.querySelector("#hidden_inputvalue14").valueOf(hidden_inputvalue14);

        let hidden_input15;
        document.querySelector("#hidden_input15").valueOf(hidden_input15);
        let hidden_inputvalue15;
        document.querySelector("#hidden_inputvalue15").valueOf(hidden_inputvalue15);

        let hidden_input16;
        document.querySelector("#hidden_input16").valueOf(hidden_input16);
        let hidden_inputvalue16;
        document.querySelector("#hidden_inputvalue16").valueOf(hidden_inputvalue16);

        let hidden_input17;
        document.querySelector("#hidden_input17").valueOf(hidden_input17);
        let hidden_inputvalue17;
        document.querySelector("#hidden_inputvalue17").valueOf(hidden_inputvalue17);

        let hidden_input18;
        document.querySelector("#hidden_input18").valueOf(hidden_input18);
        let hidden_inputvalue18;
        document.querySelector("#hidden_inputvalue18").valueOf(hidden_inputvalue18);

        let hidden_input19;
        document.querySelector("#hidden_input19").valueOf(hidden_input19);
        let hidden_inputvalue19;
        document.querySelector("#hidden_inputvalue19").valueOf(hidden_inputvalue19);

        let hidden_input20;
        document.querySelector("#hidden_input20").valueOf(hidden_input20);
        let hidden_inputvalue20;
        document.querySelector("#hidden_inputvalue20").valueOf(hidden_inputvalue20);

        let hidden_input21;
        document.querySelector("#hidden_input21").valueOf(hidden_input21);
        let hidden_inputvalue21;
        document.querySelector("#hidden_inputvalue21").valueOf(hidden_inputvalue21);

        let hidden_input22;
        document.querySelector("#hidden_input22").valueOf(hidden_input22);
        let hidden_inputvalue22;
        document.querySelector("#hidden_inputvalue22").valueOf(hidden_inputvalue22);

        let hidden_input23;
        document.querySelector("#hidden_input23").valueOf(hidden_input23);
        let hidden_inputvalue23;
        document.querySelector("#hidden_inputvalue23").valueOf(hidden_inputvalue23);

        let hidden_input24;
        document.querySelector("#hidden_input24").valueOf(hidden_input24);
        let hidden_inputvalue24;
        document.querySelector("#hidden_inputvalue24").valueOf(hidden_inputvalue24);
        //get form action url
        let getActionElement = html.getElementsByTagName('form');
        for (index = 0; index < getActionElement.length; ++index)
        {
            let optin_code_action_url = getActionElement[index].action;
        }

        let optin_code_action_url;
        document.querySelector("#optin_code_action_url").valueOf(optin_code_action_url);
        console.log(optin_code_action_url)


    }

    const onFormSubmit = (e)=>{
        e.preventDefault();


            
    }

    /*function myFunction()
    {
        let optin_code = document.querySelector("#optin_code_input").values(optin_code_input);
        let parser = new DOMParser();
        let html = parser.parseFromString(optin_code, 'text/html');
        let inputs = html.getElementsByTagName('input');
        let index;
        for (index = 0; index < inputs.length; ++index)
        {
            if(inputs[index].name === 'email')
            {
                let optin_code_email_attr = 'email';
            }else if(inputs[index].name == 'EMAIL'){
                let optin_code_email_attr = 'EMAIL';
            }

            if(inputs[index].name == 'u')
            {
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
            }else if(inputs[index].name == 'FNAME'){
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
            }else if(inputs[index].name == 'first_name'){
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
            }else if(inputs[index].name == 'field_firstname'){
                let hidden_input1 = inputs[index].name;
                let hidden_inputvalue1 = inputs[index].value;
            }

            if(inputs[index].name == 'f')
            {
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
            }else if(inputs[index].name == 'LNAME'){
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
            }else if(inputs[index].name == 'last_name'){
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
            }else if(inputs[index].name == 'field_lastname'){
                let hidden_input2 = inputs[index].name;
                let hidden_inputvalue2 = inputs[index].value;
            }

            if(inputs[index].name == 'c')
            {
                let hidden_input3 = inputs[index].name;
                let hidden_inputvalue3 = inputs[index].value;
            }

            if(inputs[index].name == 'm')
            {
                let hidden_input4 = inputs[index].name;
                let hidden_inputvalue4 = inputs[index].value;
            }

            if(inputs[index].name == 'v')
            {
                let hidden_input5 = inputs[index].name;
                let  hidden_inputvalue5 = inputs[index].value;
            }

            if(inputs[index].name == 'act')
            {
                let hidden_input6 = inputs[index].name;
                let hidden_inputvalue6 = inputs[index].value;
            }

            if(inputs[index].name == 'campaign_token')
            {
                let hidden_input7 = inputs[index].name;
                let hidden_inputvalue7 = inputs[index].value;
            }

            if(inputs[index].name == 'start_day')
            {
                let hidden_input8 = inputs[index].name;
                let hidden_inputvalue8 = inputs[index].value;
            }
// campaigndashboard
            if(inputs[index].name == 'publiclistid')
            {
                let hidden_input9 = inputs[index].name;
                let hidden_inputvalue9 = inputs[index].value;
            }
            if(inputs[index].name == 'submit')
            {
                let hidden_input10 = inputs[index].name;
                let hidden_inputvalue10 = inputs[index].value;
            }

            if(inputs[index].name == 'publicaccountid')
            {
                let hidden_input11 = inputs[index].name;
                let hidden_inputvalue11 = inputs[index].value;
            }
            if(inputs[index].name == 'returnUrl')
            {
                let hidden_input12 = inputs[index].name;
                let hidden_inputvalue12 = inputs[index].value;
            }
            if(inputs[index].name == 'activationReturnUrl')
            {
                let hidden_input13 = inputs[index].name;
                let hidden_inputvalue13 = inputs[index].value;
            }

            if(inputs[index].name == 'alreadyactiveurl')
            {
                let hidden_input14 = inputs[index].name;
                let hidden_inputvalue14 = inputs[index].value;
            }

            if(inputs[index].name == 'activationTemplate')
            {
                let hidden_input15 = inputs[index].name;
                let hidden_inputvalue15 = inputs[index].value;
            }

            if(inputs[index].name == 'source')
            {
                let hidden_input16 = inputs[index].name;
                let hidden_inputvalue16 = inputs[index].value;
            }

            if(inputs[index].name == 'verifyemail')
            {
                let hidden_input17 = inputs[index].name;
                let hidden_inputvalue17 = inputs[index].value;
            }

            if(inputs[index].name == 'captcha')
            {
                let hidden_input18 = inputs[index].name;
                let hidden_inputvalue18 = inputs[index].value;
            }

            if(inputs[index].name == 'notifyEmail')
            {
                let hidden_input19 = inputs[index].name;
                let hidden_inputvalue19 = inputs[index].value;
            }

            if(inputs[index].name == 'consenttracking')
            {
                let hidden_input20 = inputs[index].name;
                let hidden_inputvalue20 = inputs[index].value;
            }

            if(inputs[index].name == 'name')
            {
                let hidden_input21 = inputs[index].name;
                let hidden_inputvalue21 = inputs[index].value;
            }

            if(inputs[index].name == 'list')
            {
                let hidden_input22 = inputs[index].name;
                let hidden_inputvalue22 = inputs[index].value;
            }

            if(inputs[index].name == 'form')
            {
                let hidden_input23 = inputs[index].name;
                let hidden_inputvalue23 = inputs[index].value;
            }

            if(inputs[index].name == 'updated_format')
            {
                let hidden_input24 = inputs[index].name;
                let hidden_inputvalue24 = inputs[index].value;
            }

        }


        document.querySelector("#optin_code_email_attr").values(optin_code_email_attr);

        document.querySelector("#hidden_input1").values(hidden_input1);
        document.querySelector("#hidden_inputvalue1").values(hidden_inputvalue1);

        document.querySelector("#hidden_input2").values(hidden_input2);
        document.querySelector("#hidden_inputvalue2").values(hidden_inputvalue2);

        document.querySelector("#hidden_input3").value(hidden_input3);
        document.querySelector("#hidden_inputvalue3").value(hidden_inputvalue3);

        document.querySelector("#hidden_input4").value(hidden_input4);
        document.querySelector("#hidden_inputvalue4").value(hidden_inputvalue4);

        document.querySelector("#hidden_input5").value(hidden_input5);
        document.querySelector("#hidden_inputvalue5").value(hidden_inputvalue5);

        document.querySelector("#hidden_input6").value(hidden_input6);
        document.querySelector("#hidden_inputvalue6").value(hidden_inputvalue6);

        document.querySelector("#hidden_input7").value(hidden_input7);
        document.querySelector("#hidden_inputvalue7").value(hidden_inputvalue7);

        document.querySelector("#hidden_input8").value(hidden_input8);
        document.querySelector("#hidden_inputvalue8").value(hidden_inputvalue8);

        document.querySelector("#hidden_input9").value(hidden_input9);
        document.querySelector("#hidden_inputvalue9").value(hidden_inputvalue9);

        document.querySelector("#hidden_input10").value(hidden_input10);
        document.querySelector("#hidden_inputvalue10").value(hidden_inputvalue10);

        document.querySelector("#hidden_input11").value(hidden_input11);
        document.querySelector("#hidden_inputvalue11").value(hidden_inputvalue11);

        document.querySelector("#hidden_input12").value(hidden_input12);
        document.querySelector("#hidden_inputvalue12").value(hidden_inputvalue12);

        document.querySelector("#hidden_input13").value(hidden_input13);
        document.querySelector("#hidden_inputvalue13").value(hidden_inputvalue13);

        document.querySelector("#hidden_input14").value(hidden_input14);
        document.querySelector("#hidden_inputvalue14").value(hidden_inputvalue14);

        document.querySelector("#hidden_input15").value(hidden_input15);
        document.querySelector("#hidden_inputvalue15").value(hidden_inputvalue15);

        document.querySelector("#hidden_input16").value(hidden_input16);
        document.querySelector("#hidden_inputvalue16").value(hidden_inputvalue16);

        document.querySelector("#hidden_input17").value(hidden_input17);
        document.querySelector("#hidden_inputvalue17").value(hidden_inputvalue17);

        document.querySelector("#hidden_input18").value(hidden_input18);
        document.querySelector("#hidden_inputvalue18").value(hidden_inputvalue18);

        document.querySelector("#hidden_input19").value(hidden_input19);
        document.querySelector("#hidden_inputvalue19").value(hidden_inputvalue19);

        document.querySelector("#hidden_input20").value(hidden_input20);
        document.querySelector("#hidden_inputvalue20").value(hidden_inputvalue20);

        document.querySelector("#hidden_input21").value(hidden_input21);
        document.querySelector("#hidden_inputvalue21").value(hidden_inputvalue21);

        document.querySelector("#hidden_input22").value(hidden_input22);
        document.querySelector("#hidden_inputvalue22").value(hidden_inputvalue22);

        document.querySelector("#hidden_input23").value(hidden_input23);
        document.querySelector("#hidden_inputvalue23").value(hidden_inputvalue23);

        document.querySelector("#hidden_input24").value(hidden_input24);
        document.querySelector("#hidden_inputvalue24").value(hidden_inputvalue24);
        //get form action url
        var getActionElement = html.getElementsByTagName('form');
        for (index = 0; index < getActionElement.length; ++index)
        {
            var optin_code_action_url = getActionElement[index].action;
        }

        document.querySelector("#optin_code_action_url").val(optin_code_action_url);

    }*/

    /*  useEffect(()=>{
          getOptinInfo();
      }, [optin])*/


    return(
        <>
            <Navbar />
            <form onSubmit={onFormSubmit}>
                 <textarea placeholder='paste opt in code' name='optionCode' id='optin_code_input'  onChange={(e)=>handleInputChange(e)}/> <br/>
                <input type="hidden" name="optin_code_email_attr" id="optin_code_email_attr" />
                <input type="hidden" name="optin_code_action_url" id="optin_code_action_url" />
                <input type="hidden" name="hidden_input1" id="hidden_input1" />
                <input type="hidden" name="hidden_inputvalue1" id="hidden_inputvalue1" />
                <input type="hidden" name="hidden_input2" id="hidden_input2" />
                <input type="hidden" name="hidden_inputvalue2" id="hidden_inputvalue2" />
                <input type="hidden" name="hidden_input3" id="hidden_input3" />
                <input type="hidden" name="hidden_inputvalue3" id="hidden_inputvalue3" />
                <input type="hidden" name="hidden_input4" id="hidden_input4" />
                <input type="hidden" name="hidden_inputvalue4" id="hidden_inputvalue4" />
                <input type="hidden" name="hidden_input5" id="hidden_input5" />
                <input type="hidden" name="hidden_inputvalue5" id="hidden_inputvalue5" />
                <input type="hidden" name="hidden_input6" id="hidden_input6" />
                <input type="hidden" name="hidden_inputvalue6" id="hidden_inputvalue6" />
                <input type="hidden" name="hidden_input7" id="hidden_input7" />
                <input type="hidden" name="hidden_inputvalue7" id="hidden_inputvalue7" />
                <input type="hidden" name="hidden_input8" id="hidden_input8" />
                <input type="hidden" name="hidden_inputvalue8" id="hidden_inputvalue8" />
                <input type="hidden" name="hidden_input9" id="hidden_input9" />
                <input type="hidden" name="hidden_inputvalue9" id="hidden_inputvalue9" />
                <input type="hidden" name="hidden_input10" id="hidden_input10" />
                <input type="hidden" name="hidden_inputvalue10" id="hidden_inputvalue10" />
                <input type="hidden" name="hidden_input11" id="hidden_input11" />
                <input type="hidden" name="hidden_inputvalue11" id="hidden_inputvalue11" />
                <input type="hidden" name="hidden_input12" id="hidden_input12" />
                <input type="hidden" name="hidden_inputvalue12" id="hidden_inputvalue12" />
                <input type="hidden" name="hidden_input13" id="hidden_input13" />
                <input type="hidden" name="hidden_inputvalue13" id="hidden_inputvalue13" />
                <input type="hidden" name="hidden_input14" id="hidden_input14" />
                <input type="hidden" name="hidden_inputvalue14" id="hidden_inputvalue14" />
                <input type="hidden" name="hidden_input15" id="hidden_input15" />
                <input type="hidden" name="hidden_inputvalue15" id="hidden_inputvalue15" />
                <input type="hidden" name="hidden_input16" id="hidden_input16" />
                <input type="hidden" name="hidden_inputvalue16" id="hidden_inputvalue16" />
                <input type="hidden" name="hidden_input17" id="hidden_input17" />
                <input type="hidden" name="hidden_inputvalue17" id="hidden_inputvalue17" />
                <input type="hidden" name="hidden_input18" id="hidden_input18" />
                <input type="hidden" name="hidden_inputvalue18" id="hidden_inputvalue18" />
                <input type="hidden" name="hidden_input19" id="hidden_input19" />
                <input type="hidden" name="hidden_inputvalue19" id="hidden_inputvalue19" />
                <input type="hidden" name="hidden_input20" id="hidden_input20" />
                <input type="hidden" name="hidden_inputvalue20" id="hidden_inputvalue20" />
                <input type="hidden" name="hidden_input21" id="hidden_input21" />
                <input type="hidden" name="hidden_inputvalue21" id="hidden_inputvalue21" />
                <input type="hidden" name="hidden_input22" id="hidden_input22" />
                <input type="hidden" name="hidden_inputvalue22" id="hidden_inputvalue22" />
                <input type="hidden" name="hidden_input23" id="hidden_input23" />
                <input type="hidden" name="hidden_inputvalue23" id="hidden_inputvalue23" />
                <input type="hidden" name="hidden_input24" id="hidden_input24" />
                <input type="hidden" name="hidden_inputvalue24" id="hidden_inputvalue24" />
                 <button type='submit'>Submit</button>
            </form>
            </>
    )
}
export default AutoResponder;
