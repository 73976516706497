import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {gdprAction} from "../../actions/authAction";
import SweetAlert from "react-bootstrap-sweetalert";

const PrivacyGdpr = () => {

    const dispatch = useDispatch();

    const [button, setButton] = useState('Delete Data');
    const [msgData ,setMsgData] = useState('You will not be able to recover this file');
    const [showDelete, setShowDelete] = useState(false);
    const [type, setType] = useState('delete');

    const modalData = [
        {
            message : 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        },
        {
            message : 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your account, you would have to contact our support team.'
        },
        {
            message : 'PlayerReel stores your private information. If you wish to download these data, you can do that by clicking Download My Data button.'
        }
    ]

    const onConfirm = () => {
        setButton('Please Wait...');

        if(type === 'delete'){
            dispatch(gdprAction('','delete-account',setShowDelete))
        }
        else if(type === 'stop'){
            dispatch(gdprAction('','disable-account',setShowDelete));
        }
        else if(type === 'download'){
            dispatch(gdprAction('','download-user-data',setShowDelete));
        }

    }

    const handleClick = (e) =>{
        setShowDelete(true);

        if(e === 'delete'){
            setMsgData(modalData[0].message);
            setButton('Delete Data');
            setType('delete');
        }
        else if(e === 'stop'){
            setMsgData(modalData[1].message);
            setButton('Stop Processing');
            setType('stop');
        }
        else if(e === 'download'){
            setMsgData(modalData[2].message);
            setButton('Download');
            setType('download');
        }
    }

    const onCancel = (e) => {
        setShowDelete(false);
    }


    return(
        <>
         {/*   <button className="btn btn-primary ml-2" onClick={()=>handleClick('download')}>Download My Data</button>
            <button className="btn btn-warning ml-2" onClick={()=>handleClick('stop')}>Stop Processing My Data</button>
            <button className="btn btn-danger ml-2" onClick={()=>handleClick('delete')}>Delete My Data</button>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                {msgData}
            </SweetAlert>*/}
            <section className="privacy-setting">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mx-auto">
                            <div className="manage-data-box">
                                <div className="manage-data-box-content">
                                    <p>PlayerReel stores your private information like your name, email. It also stores
                                        your uploaded graphics such as images, videos and your music files along with
                                        TTS ( Text to speech). If you wish to download these data, you can do that "by
                                        clicking" download your data.</p>
                                    <button type="button" className="btn btn-bundle blue" onClick={()=>handleClick('download')}>Download My Data</button>
                                </div>
                                <div className="manage-data-box-content">
                                    <p>If you wish to stop your private data from getting processed on PlayerReel you can
                                        click the button to do so. Please note that doing this will disable your account
                                        and you will not be able to use PlayerReel further. In order to reactivate your
                                        account, you would have to contact our support team at <a href="https://support.vineasx.com/" target="_blank">support@vineasx.com</a>. <font color="#f27277">Please Proceed With Caution.</font>
                                    </p>
                                    <button type="button" className="btn btn-bundle orange" onClick={()=>handleClick('stop')}>Stop Processing My Data
                                    </button>
                                </div>
                                <div className="manage-data-box-content">
                                    <p>If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use PlayerReel further. You cannot undo this step from support also. So if you wish to re-use PlayerReel again, then you will have to start all over again from scratch. <font color="#f27277">Please Proceed With Caution.</font></p>
                                    <button type="button" className="btn btn-bundle red" onClick={()=>handleClick('delete')}>Delete My Data</button>
                                </div>
                            </div>
                        </div>
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText={button}
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="light"
                            title="Are you sure?"
                            onConfirm={(e)=>onConfirm(e)}
                            onCancel={(e)=>onCancel(e)}
                            focusCancelBtn
                            show={showDelete}
                        >
                            {msgData}
                        </SweetAlert>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyGdpr;
